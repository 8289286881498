/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
import { useSelector , useDispatch } from "react-redux";
import fleche from '../../assets/images/arrow-left.svg';
import imageUploadBtn from '../../assets/images/upload_pofile.svg';
import camera from '../../assets/images/camera.png';
import eyeOpen from '../../assets/images/eye.svg';
import eyeClosed from '../../assets/images/eye-closed.svg';
import AlertComponent from "../common/AlertComponent";
import errors from "../../helpers/Errors";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { verfiedMail } from "../../redux/signup/actions";

import { Container, Col, Row, Button, Form, FormControl, FormLabel, FormGroup, Image, Spinner} from 'react-bootstrap';

function ProfilSignupForm({onNext}){
    
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const signupState = useSelector((state) => state.signup);
    const { formData, error, emailVerified } = signupState;

    const [profilData,setProfilData] = useState({
        fullName:formData?.fullName || "",
        ecoleAffiliate : formData?.ecoleAffiliate || "",
        rib: formData?.rib || "",
        tel : formData?.tel || "",
        about : formData?.about || "",
        email : formData?.email  || "" ,
        password:  formData?.password  || "" ,
        profilePicture: formData?.profilePicture || null,
    });
    const [validated, setValidated] = useState(false);
    const [profilUrl, setProfilUrl] = useState( formData?.profilePicture ? URL.createObjectURL(formData.profilePicture) : null);
    const [eyePassword, setEyePassword] = useState(eyeOpen);
    const [eyeActive, setEyeActive] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isTelephoneValid, setIsTelephoneValid] = useState(true);



    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if(form.checkValidity() === false || !isValidPassword || !isTelephoneValid){
            e.preventDefault();
            e.stopPropagation();
            setIsValidPassword(!isValidPassword);
            setValidated(true);
        }else{
            setValidated(false);
            dispatch(verfiedMail(profilData.email));
        }
    }

    const handleUpload = (e) => {
        const profilePicture = e.target.files[0];
        setProfilUrl(URL.createObjectURL(profilePicture));
        setProfilData({
          ...profilData,
          profilePicture,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfilData({
          ...profilData,
          [name]: value,
        });
    };


    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setIsValidPassword(validator.isStrongPassword(value));
        setProfilData({
          ...profilData,
          [name]: value,
        });
    };

    const handleTelephoneChange = (e) => {
        const regexNumeroFrance = /^(?:(?:\+|00)33|0)[1-9](?:(?:(?:(?:\d{2}){1})\d{2})\d{2})\d{2}\d{2}$/;
        const { name, value } = e.target;
        if(value){
            setIsTelephoneValid(regexNumeroFrance.test(value));
        }else{
            setIsTelephoneValid(true);
        }
        setProfilData({
          ...profilData,
          [name]: value,
        });
    }

    const showPassword = (e) => {
        setEyeActive(!eyeActive);
        if (eyeActive) {
            setEyePassword(eyeOpen);
        } else {
            setEyePassword(eyeClosed);
        }
        
    };

    const onPrev = () => {
        navigate('/inscription');
    }


    useEffect(() => {
        if(!formData?.privilege) navigate('/inscription');  
    },[])
    
    useEffect(() => {
        if(emailVerified){
          onNext(profilData);
        }
      }, [emailVerified]);

    return (
        <>
            <Container>
            <div className="mobile_div">
                <div className="col-md-12 col-lg-12 dv_retour">
                <a className="black leftop"  onClick={onPrev}>
                    <img src={fleche} alt="fleche gauche" />
                </a>
                <ul className="inline_progress">
                    <li className="current width50" >&nbsp;</li>
                    <li className='width50'>&nbsp;</li>
                </ul>
                <h2 className="titre_h2">Configurer votre BDE</h2>
                </div>
                <div className="col-md-12 col-lg-12 top20">
                <Form className="formLogin" noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                    <Col md={12} lg={12} className="center">
                        <input accept=".jpg, .jpeg, .png" type="file" name="profilePicture" id="profilePicture" style={{ display: 'none' }} onChange={handleUpload} />
                        <Button style={{backgroundImage: `url(${profilUrl ? profilUrl : ''  })`}} className="btn_upload" type="button" onClick={() => document.getElementById('profilePicture').click()}>
                            { !profilUrl && (<img src={imageUploadBtn} alt="profil" className='btn_profil' />) }
                            <img src={camera} alt="profil" className='camera' />
                        </Button>
                    </Col>
                    
                    <Col md={12} lg={12}>
                        <Form.Group className="mb-3">
                        <FormLabel>Nom BDE</FormLabel>
                        <div className="inline_input">
                            <Form.Control className="input-rond" type="text" id="fullName" placeholder="Nom BDE" name="fullName" value={profilData.fullName} onChange={handleChange} required></Form.Control>
                            <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                        </div>
                        </Form.Group> 
                    </Col>
                    <Col md={12} lg={12}>
                        <Form.Group className="mb-3">
                        <FormLabel>Ecole affilié</FormLabel>
                        <div className="inline_input">
                            <Form.Control className="input-rond" type="text" id="ecole" placeholder="Ecole affilié" name="ecoleAffiliate" value={profilData.ecoleAffiliate} onChange={handleChange}></Form.Control>
                            {/* <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback> */}
                        </div>
                        </Form.Group> 
                    </Col>
                    <Col md={12} lg={12}>
                        <Form.Group className="mb-3">
                        <FormLabel>Clé RIB BDE</FormLabel>
                        <div className="inline_input">
                            <Form.Control className="input-rond" type="text" id="rib" placeholder="4242 4242 4242 4242" name="rib" value={profilData.rib} onChange={handleChange}></Form.Control>
                            {/* <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback> */}
                        </div>
                        </Form.Group> 
                    </Col>
                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3">
                        <FormLabel>E-mail</FormLabel>
                        <div className="inline_input">
                            <FormControl className="input-rond" type="email" id="email" placeholder="j.dupont@residence.com" name="email" value={profilData.email} onChange={handleChange} required={true}></FormControl>
                            <Form.Control.Feedback type="invalid">Ce champ est requis et doit contenir une adresse e-mail valide !</Form.Control.Feedback>
                        </div>
                        </FormGroup>
                    </Col>

                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3">
                        <FormLabel>Mot de passe</FormLabel>
                        <div className="inline_input">
                            <FormControl className="input-rond" type={eyeActive ? "text" : "password"}  id="password" placeholder="********" name="password" value={profilData.password} onChange={handlePasswordChange} required={true}></FormControl>
                            <Button onClick={showPassword} className='btn_maso'><img alt="" src={eyePassword} className='eye_icon' /></Button>
                        </div>
                        <Form.Control.Feedback type="invalid">Ce champ est requis et doit contenir une adresse e-mail valide !</Form.Control.Feedback>
                        <Form.Text style={{ fontFamily: 'poppins' }} as="small" className={isValidPassword ? '' : 'text-danger'}>Mot de passe : 8 caractères min. avec majuscule, minuscule, chiffre et caractère spécial.</Form.Text>
                        </FormGroup>
                    </Col>

                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3">
                        <FormLabel>Téléphone</FormLabel>
                        <div className="inline_input">
                            <FormControl className="input-rond" type="text" id="telephone" placeholder="0 | +33" name="tel" value={profilData.tel} onChange={handleTelephoneChange} ></FormControl>
                            <Form.Control.Feedback type="invalid">Ce champ est requis </Form.Control.Feedback>
                        </div>
                        { !isTelephoneValid && <Form.Text style={{ fontFamily: 'poppins' }} as="small" className={'text-danger'}>Votre numero telephone n'est pas valide!</Form.Text>}
                        </FormGroup>
                    </Col>

                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3">
                        <FormLabel>Description</FormLabel>
                        <div className="inline_input">
                            <FormControl as="textarea" rows={4} className="" style={{borderRadius: '20px'}}  id="about" placeholder="" name="about" value={profilData.about} onChange={handleChange} ></FormControl>
                            <Form.Control.Feedback type="invalid">Ce champ est requis </Form.Control.Feedback>
                        </div>
                        </FormGroup>
                    </Col>

                  

                    { error  &&
                        (
                            <Col md={12} lg={12}>
                                <AlertComponent show={true} duration={3000} variant="danger" text={errors[error]} />
                            </Col>
                        )
                    }
                    
                    {/* <Col className="col-md-12 col-lg-12">
                        <div style={{ marginTop: '10px', marginBottom: '10px', minWidth: '40%', display: 'flex', justifyContent: 'center' , alignItems: 'center' }} >
                            <div>
                                <ReCAPTCHA re sitekey={apiConfig.sitekey} onChange={handleCaptcha} />
                                {showCaptchaError ? (<><br/><span className='text-danger'> Veuillez verifier que vous n'êtes pas un robot </span></>) : ''}
                            </div>                       
                        </div>
                    </Col> */}
                    <Col md={12} lg={12} className="center">
                        <div className="bottom_submit_sign">
                        <Button className="btn_signup" type="submit">
                            Suivant
                        </Button>
                        </div>
                    </Col>
                    </Row>
                </Form>
                </div>
            </div>
            </Container>
        </>
    )
}

export default ProfilSignupForm;