/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from 'react';
import { Button , Col } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import {  fetchInterets } from '../../redux/signup/actions';
import fleche from '../../assets/images/arrow-left.svg';

function InterestsSignupForm({step,onPrev,onNext}) {

    const dispatch = useDispatch();
    const formData = useSelector((state) => state.signup.formData);
    const interestData = useSelector((state) => state.signup.interets);

    const [steps, setSteps] = useState([0,1,2,3,4])
    const [interests,setInterests] = useState([]);
    const [interestSelectionnes,setInterestSelectionnes] = useState([]);

    const handleSelection = (item) => {
        if(interestSelectionnes.find((e) => e.id === item.id)){               
            setInterestSelectionnes(interestSelectionnes.filter((interest) => interest.id !== item.id));
        }else{
            setInterestSelectionnes([...interestSelectionnes,item]);
        }
    };

    const handleSubmit = () => {
        onNext({
            interests : interestSelectionnes
        });
    }

    useEffect(() => {
        dispatch(fetchInterets());
        setInterestSelectionnes(formData.interests || [] );
    },[])

    useEffect(() => {
        setInterests(interestData);
    },[interestData])


    return (
        <>
            <div className="col-md-12 col-lg-12 dv_retour">
                <div className="col-md-12 col-lg-12 dv_retour">
                    <a className='black' onClick={()=>onPrev(step)}><img src={fleche} alt="fleche gauche" /></a>
                    <ul className="inline_progress top30">
                    {steps.map((_, index) => (
                        <li key={index} className={step === index ? "current" : ""}>&nbsp;</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className="col-md-12 col-lg-12">
                <h2 className="titre_h2">Vos intérêts</h2>
                <p className='p_content'>Choisissez vos intérêts</p>
            </div>
            <div className="col-md-12 col-lg-12">
                <div className='multipleInterest'>
                    { interests && interests.map((interest) => (
                        <Button
                        key={interest.id}
                        onClick={() => handleSelection(interest)}
                        className={interestSelectionnes.find((item) => item.id === interest.id) ? "selected" : ""}
                        >
                        {interest.label}
                        </Button>
                    ))}
                </div>
                <Col md={12} lg={12} className="center">
                    <div className='bottom_submit'>  <Button className="btn_login" onClick={handleSubmit} >Suivant</Button></div>
                </Col>   
            </div>
        </>
    )
}

export default InterestsSignupForm;