export const  VERFIED_MAIL = 'VERFIED_MAIL'
export const  VERFIED_MAIL_SUCCESS = 'VERFIED_MAIL_SUCCESS'
export const  VERFIED_MAIL_FAILURE = 'VERFIED_MAIL_FAILURE'
export const  UPDATE_VERIFIED_MAIL = 'UPDATE_VERIFIED_MAIL'

export const FETCH_INTERETS = 'FETCH_INTERESTS'
export const FETCH_INTERETS_SUCCESS = 'FETCH_INTERESTS_SUCCESS'
export const FETCH_INTERETS_FAILURE = 'FETCH_INTERESTS_FAILURE'

export const SEARCH_BDES = 'SEARCH_BDES'
export const SEARCH_BDES_SUCCESS = 'SEARCH_BDES_SUCCESS'
export const SEARCH_BDES_FAILURE = 'SEARCH_BDES_FAILURE'

export const VERIFIED_CODE = 'VERIFIED_CODE'
export const VERIFIED_CODE_SUCCESS = 'VERIFIED_CODE_SUCCESS'
export const VERIFIED_CODE_FAILURE = 'VERIFIED_CODE_FAILURE'

export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const UPDATE_BDE = "UPDATE_BDE";

export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_CONFIRM_REQUEST = 'USER_CONFIRM_REQUEST';
export const USER_CONFIRM_SUCCESS = 'USER_CONFIRM_SUCCESS';
export const USER_CONFIRM_FAILURE = 'USER_CONFIRM_FAILURE';

