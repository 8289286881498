import {
    UPDATE_FROM_DATA
} from "./types" 

const initialState = {
    formData : {}
};

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FROM_DATA:
            return {
                ...state,
                formData : action.payload
            }
        default:
            return state;
    }
}
