/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
import { Form , Row ,Col , FormLabel , Button , Spinner} from "react-bootstrap";
import { useSelector , useDispatch } from "react-redux";
import validator from "validator";
import fleche from '../../assets/images/arrow-left.svg';
import { verfiedMail } from "../../redux/signup/actions";
import AlertComponent from "../common/AlertComponent";
import errors from "../../helpers/Errors";
import imageUploadBtn from '../../assets/images/upload_pofile.svg';
import camera from '../../assets/images/camera.png';
import eyeOpen from '../../assets/images/eye.svg';
import eyeClosed from '../../assets/images/eye-closed.svg';
import { useNavigate } from "react-router-dom";

function ProfilSignupForm({step,onNext}) {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [steps, setSteps] = useState([0,1,2,3,4])
    const signupState = useSelector((state) => state.signup);
    const { formData, error, emailVerified } = signupState;

    const [eyePassword, setEyePassword] = useState(eyeOpen);
    const [eyeActive, setEyeActive] = useState(false);
    
    const [validated,setValidated] = useState(false);
    const [profilData,setProfilData] = useState({
        fullName:formData?.fullName || "",
        email : formData?.email  || "" ,
        password:  formData?.password  || "" ,
        profilePicture: formData?.profilePicture || null,
    });

    const [profilUrl, setProfilUrl] = useState(formData?.profilePicture ? URL.createObjectURL(formData?.profilePicture) : null);

    const [isValidPassword, setIsValidPassword] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if(form.checkValidity() === false || !isValidPassword){
            e.preventDefault();
            e.stopPropagation();
            setIsValidPassword(false);
            setValidated(true);
        }else{
            setValidated(false);
            dispatch(verfiedMail(profilData.email));
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfilData({
          ...profilData,
          [name]: value,
        });
    };

    const handleUpload = (e) => {
        const profilePicture = e.target.files[0];
        setProfilUrl(URL.createObjectURL(profilePicture));
        setProfilData({
          ...profilData,
          profilePicture,
        });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setIsValidPassword(validator.isStrongPassword(value));
        setProfilData({
          ...profilData,
          [name]: value,
        });
    };

    useEffect(() => {

        if(!formData?.privilege) navigate('/inscription');  
    },[])

    useEffect(() => {
      if(emailVerified){
        onNext(profilData);
      }
    }, [emailVerified]);

    const showPassword = (e) => {
        setEyeActive(!eyeActive);
        if (eyeActive) {
            setEyePassword(eyeOpen);
        } else {
            setEyePassword(eyeClosed);
        }
        
    };

    const onPrev = () => {
        navigate('/inscription');
    }
      

    return (
        <>
            <div className="col-md-12 col-lg-12 dv_retour">
                <div className="col-md-12 col-lg-12 dv_retour">
                    <a className='black' onClick={onPrev}><img src={fleche} alt="fleche gauche" /></a>
                    <ul className="inline_progress top30">
                    {steps.map((_, index) => (
                        <li key={index} className={step === index ? "current" : ""}>&nbsp;</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className="col-md-12 col-lg-12">
                <h2 className="titre_h2">Créer un compte</h2>
            </div>
            <div className="col-md-12 col-lg-12 top20">
                <Form  className="formSignup" noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12} lg={12} className="center">
                            <input accept=".jpg, .jpeg, .png" type="file" name="profilePicture" id="profilePicture" style={{ display: 'none' }} onChange={handleUpload} />
                            <Button style={{backgroundImage: `url(${profilUrl ? profilUrl : ''  })`}} className="btn_upload" type="button" onClick={() => document.getElementById('profilePicture').click()}>
                                { !profilUrl && (<img src={imageUploadBtn} alt="profil" className='btn_profil' />) }
                                <img src={camera} alt="profil" className='camera' />
                            </Button>
                        </Col>
                        <Col className="top30" md={12} lg={12}>
                            <Form.Group className="mb-3">
                            <FormLabel>Nom et prénom</FormLabel>
                            <div className="inline_input">
                                <Form.Control className="input-rond" type="text" id="fullName" placeholder="Jean Dupont" name="fullName" value={profilData?.fullName} onChange={handleChange} required></Form.Control>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                            </div>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={12}>
                            <Form.Group className="mb-3">
                            <FormLabel>E-mail</FormLabel>
                            <div className="inline_input">
                                <Form.Control className="input-rond" type="email" id="email" placeholder="j.dupont@residence.com" name="email" value={profilData?.email} onChange={handleChange} required={true}></Form.Control>
                                <Form.Control.Feedback type="invalid">Ce champ est requis et doit contenir une adresse e-mail valide !</Form.Control.Feedback>
                            </div>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={12}>
                            <Form.Group className="mb-3">
                                <FormLabel>Mot de passe</FormLabel>
                                <div className="inline_input">
                                    <Form.Control className="input-rond" type={eyeActive ? "text" : "password"} id="password" placeholder="..........." name="password" value={profilData?.password} onChange={handlePasswordChange} required={true}></Form.Control>
                                    <Button onClick={showPassword} className='btn_maso'><img src={eyePassword} className='eye_icon' /></Button>
                                </div>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                                <Form.Text style={{ fontFamily: 'poppins' }} as="small" className={isValidPassword ? '' : 'text-danger'}>Mot de passe : 8 caractères min. avec majuscule, minuscule, chiffre et caractère spécial.</Form.Text>
                            </Form.Group>
                        </Col>
                        { error  &&
                            (
                                <Col md={12} lg={12}>
                                    <AlertComponent show={true} duration={3000} variant="danger" text={errors[error]} />
                                </Col>
                            )
                        }
                        
                        <Col md={12} lg={12} className="center">
                            <div className="bottom_submit_sign">
                            <Button className="btn_signup" type="submit">
                               Suivant
                            </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

export default ProfilSignupForm;