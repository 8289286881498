import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, Form, FormLabel, FormGroup, Alert, Carousel, CarouselItem, FormControl} from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoMobile from '../images/logo-mobile.png';
import logoOffre from '../images/offre.png';
import logoSocial from '../images/social.png';
import logoMessage from '../images/message.png';
import logoAccount from '../images/account.png';
import './ListEventStudentPage.css';
import MenuBottomStudent from './MenuBottomStudent';
import loupe from '../images/loupe.png';
import filter from '../images/filter.svg';
import iconeMap from '../images/map.svg';
import iconeList from '../images/list.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import EventNearYouStudent from './EventNearYouStudent';
import EventRecommendedStudent from './EventRecommendedStudent';
import TopBarEventStudent from './TopBarEventStudent';


function ListEventStudentPage (props) {

    return (
        <>
        <Container className='full'>
            <div className='col-md-12 col-lg-12'>
                <TopBarEventStudent />
                <div className='form_search top30'>
                    <Form className='form_student_activity'>
                        <FormGroup >
                            <div className='inline_input large'>
                                <img className='loupe' src={loupe} alt="loupe" />
                                <FormControl className='input-rond placeholdermarge' type="text" id="loupe" placeholder="recherche" name="loupe"  required={true}></FormControl>
                            </div>
                        </FormGroup>
                        <Button id="btn_filter"><img src={filter} alt="submit" /></Button>
                    </Form>
                </div>
                <EventNearYouStudent />
                <EventRecommendedStudent />
            </div>
            <div className='icone_navigation'>
                <a href="/student-event-map">
                    <svg id="icone_map" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.83301 7.86329V21.1966L8.66634 17.8633L15.333 21.1966L21.1663 17.8633V4.52995L15.333 7.86329L8.66634 4.52995L2.83301 7.86329Z" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.66699 4.52995V17.8633" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.333 7.86328V21.1966" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="/student-event" className='current'>
                    <svg id="icone_list" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 6.86328H21" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 12.8633H21" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8 18.8633H21" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 6.86328H3.01" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 12.8633H3.01" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M3 18.8633H3.01" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
            <div className='icone_navigation_right'>
                <a className='btn_create_event' href="/bde-create-event"><FontAwesomeIcon icon={faPlusCircle} /></a>
            </div>
            <MenuBottomStudent currentMenu='activity' />
        </Container>
        </>
    );
}
export default ListEventStudentPage;