import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import './ListEventStudentPage.css';
import club from '../images/nightclub.jpg';
import latino from '../images/latino.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function EventRecommendedStudent (props) {


    return (
        <>
      
        <div className='list_event_nearyou content'>
            <h2 className='back_h2'>Recommandés</h2>
            <div className='carousel_event_recommended'>
                <div className='a_recommended'>
                    <div className='img_recommend'>
                        <img src={latino} className='couv_recommend' />
                    </div>
                    <div className='desc_recommend'>
                        <span className='title_recommend'>Les Misérables</span>
                        <span className='lieu_recommend'><FontAwesomeIcon icon={faMapMarkerAlt} /> Theatre de la Ville</span>
                        <span className='date_recommend'>12 Aug - 16 Aug</span>
                        <span className='price_recommend'>21 &euro;</span><span className='price_paid'>18 &euro;</span>
                    </div>
                </div>

                <div className='a_recommended'>
                    <div className='img_recommend'>
                        <img src={club} className='couv_recommend' />
                    </div>
                    <div className='desc_recommend'>
                        <span className='title_recommend'>Night show </span>
                        <span className='lieu_recommend'><FontAwesomeIcon icon={faMapMarkerAlt} /> Theatre de la Ville</span>
                        <span className='date_recommend'>12 Aug - 16 Aug</span>
                        <span className='price_recommend'>21 &euro;</span><span className='price_paid'>18 &euro;</span>
                    </div>
                </div>

                <div className='a_recommended'>
                    <div className='img_recommend'>
                        <img src={latino} className='couv_recommend' />
                    </div>
                    <div className='desc_recommend'>
                        <span className='title_recommend'>IBIZA show </span>
                        <span className='lieu_recommend'><FontAwesomeIcon icon={faMapMarkerAlt} /> Theatre de la Ville</span>
                        <span className='date_recommend'>12 Aug - 16 Aug</span>
                        <span className='price_recommend'>21 &euro;</span><span className='price_paid'>18 &euro;</span>
                    </div>
                </div>
                    
            </div>
        </div>
    
        </>
    );
}
export default EventRecommendedStudent;