import React, { useState, useRef, useEffect} from 'react';
import {Container, Row, Col, Form, FormControl, FormGroup, Button, FormLabel, Modal} from 'react-bootstrap';
import fleche from './images/arrow-left.svg';
import { faCheckCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

//import icon from './images/location.png';
import icon from "./constants";

function CreateEventPage (props) {

   
    const [validated, setValidated] = useState(false);
    const [events, setEvents] = useState({});
    const [categories, setCategories] = useState([]);
    const [show, setShow] = useState(true);
    const [showExterne, setShowExterne] = useState(true);
    const [couverture, setCouverture] = useState({});
    const [step, setStep] = useState(0);
    const [location, setLocation] = useState('');
    const [markers, setMarkers] = useState([]);
    const center = [45.269169177925754, -0.5231516014256281];
    const purpleOptions = { color: "white" };
    const [participant, setParticipant] = useState(0);
    const [locations, setLocations] = useState([]);
    const [arrDateEvent, setArrDateEvent] = useState([]);
   

    const [captchaToken, setCaptchaToken] = useState(null);
    const [showModaLocation, setShowModaLocation] = useState(false);
    const [showModalDateTime, setShowModalDateTime] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState({});


    const handleShowModaLocation = () => {
        setShowModaLocation(true);
    };
    
    const handleCloseModaLocation = () => {
        setShowModaLocation(false);
    };

    const handleShowModalDateTime = () => {
        setShowModalDateTime(true);
    };
    
    const handleCloseModalDateTime = () => {
        setShowModalDateTime(false);
    };

  

    const handleChange = (event) => {
        console.log("handleChange ");
        let index = event.nativeEvent.target.selectedIndex;
        const name = event.target.name;
        const value = event.target.value;
        
        if (name == "categorie" ) {
            const libelle = event.nativeEvent.target[index].text;
            console.log("NAME = " + name + " VALUE = " + value + " TEXT = " + libelle);
            setEvents(values => ({...values, [name]: {id:value,name:libelle}}));
        } else if (name == "modeReservation") {

            const libelle = event.nativeEvent.target[index].text;
            console.log("NAME = " + name + " VALUE = " + value + " TEXT = " + libelle);
            setEvents(values => ({...values, [name]: {id:value,name:libelle}}));
            //afficher coupon filed 
            if (value == "coupon") {
                setShow(false);
            } else {
                setShow(true);
            }

            //si reservation externe
            if (value == "reservation-interne") {
                setShowExterne(false);
            } else {
                setShowExterne(true);
            }
           
        }  else {
            setEvents(values => ({...values, [name]: value}))
        }
    
        if (name == "couverture") {
            setCouverture(event.target.files[0]);
        }
        
    
    }

    const handleSliderChange = (event) => {
        // Mettez à jour la valeur du champ Range lorsque l'utilisateur fait un changement
        //setSliderValue(event.target.value);
        setParticipant(event.target.value);
    };


    const handleLocation = (event) => {
        console.log(event);
        setLocations([...locations, event.target.value]);
    };

    const handleDate = (event) => {
        console.log(event);
        currentDateTime.date = event.target.value;
    };

    const handleHeure = (event) => {
        console.log(event);
        currentDateTime.heure = event.target.value;
    };

    const addDateTime = (event) => {
        let tempsObjetDate = {date:currentDateTime.date, heure:currentDateTime.heure};
        //console.log(tempsObjetDate);
        //setCurrentDateTime(tempsObjetDate);
        setShowModalDateTime(false);
        setArrDateEvent([...arrDateEvent, tempsObjetDate]);
    };
    
    const handleSubmit = (e) => {
        const totalStep = 3;
        e.preventDefault();
        console.log("NEXT =>");
        const form = e.currentTarget;
        if ( form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        
        } else {

            //setValidated(false);
            var next = step +1;
           
            if (next <= totalStep) {
                setStep(next);
                console.log("STEP =>" + next);
            } else {
                console.log("TAPITRA SIGNUP");
            }
            
        }
        setValidated(true);
    };

   

    return (
        <>
        <Container fluid>
        <Form className='formLogin' noValidate validated={validated} onSubmit={handleSubmit}>
            <div id="step0" style={{ display: `${step === 0 ? "block" : "none"}` }} >
                <div className='col-md-12 col-lg-12 dv_retour'>
                    <a className='black' href="/"><img src={fleche} alt="fleche gauche" /> Créer un événement</a>
                    <ul className='inline_progress event'>
                        <li className='current'>&nbsp;</li>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                    </ul>
                    <h2 className="titre_h2">Quel est votre événement ?</h2>
                </div>
                <div className='col-md-12 col-lg-12 conteneur'>
                    <Row>
                        <Col md={12} lg={12}>
                            <FormGroup className="mb-3" >
                                <FormLabel>Nom de l'événement</FormLabel>
                                <div className='inline_input'>
                                    <FormControl className='input-rond' type="text" id="name" placeholder="Event Name" name="name" value={(events.name)  ? events.name:""} onChange={handleChange} required={true}></FormControl>
                                </div>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <FormGroup className="mb-3" >
                                <FormLabel>Catégorie de l'événement : </FormLabel>
                                <Form.Select className="input-rond" aria-label="catégorie activite" id="categorie" name="categorie" onChange={handleChange}>
                                    <option>[ séléctionner une catégorie]</option>
                                    <option>Movies</option>
                                    <option>Arts</option>
                                </Form.Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Description</Form.Label>
                                <Form.Control className="input-rond" as="textarea" id="description" name="description" placeholder="Description" rows={3} value={(events.description)  ? events.description:""} onChange={handleChange}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                            <Form.Group className="mb-3" >
                                <Form.Label>Nombre de participant</Form.Label>
                                <Form.Range
                                    name="participant"
                                    id="participant"
                                    value={participant}
                                    onChange={handleSliderChange}
                                    min={0}
                                    max={400}
                                    step={1}
                                />
                                <span>{participant}</span>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                        <FormGroup className="mb-3" >
                                <FormLabel>Upload image </FormLabel>
                                <FormControl className="input-rond" type="file" id="couverture" placeholder="image de couverture" name="couverture" value={(events.couverture)  ? events.couverture:""} onChange={handleChange} required={true}></FormControl>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                            </FormGroup>
                        </Col>
                    </Row>
                   
                </div>
            </div>
            <div id="step1" style={{ display: `${step === 1 ? "block" : "none"}` }} >
                <div className='col-md-12 col-lg-12 dv_retour'>
                    <a className='black' href="/"><img src={fleche} alt="fleche gauche" /> Create Event</a>
                    <ul className='inline_progress event'>
                        <li className='current'>&nbsp;</li>
                        <li className='current'>&nbsp;</li>
                        <li>&nbsp;</li>
                    </ul>
                   
                </div> 
                <div className='col-md-12 col-lg-12'>
                    <Row>  
                        <Col md={12} lg={12}>
                            <h2 className="titre_h2">Lieu</h2>
                            <ul className='ul_lieu'>
                                {locations.map((element, index) => (
                                    <li key={index}>{element}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    <Row>  
                        <Col md={12} lg={12} className="center">
                           <Button className="btn_add" onClick={handleShowModaLocation} ><FontAwesomeIcon icon={faPlus} /> Ajouter un lieu</Button>
                        </Col>
                    </Row>
                    <Row>  
                        <Col md={12} lg={12}>
                            <h2 className="titre_h2">Date événement</h2>
                            <ul className='ul_lieu'>
                                {arrDateEvent.map((element, index) => (
                                    <li key={index}>{element.date} à {element.heure}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    <Row>  
                        <Col md={12} lg={12} className="center">
                           <Button className="btn_add" onClick={handleShowModalDateTime} ><FontAwesomeIcon icon={faPlus} /> Ajouter la date et l'heure</Button>
                        </Col>
                    </Row>
                    
                    <Modal show={showModaLocation} onHide={handleCloseModaLocation}>
                        <Modal.Body>
                            <Row>
                                <Col md={12} lg={12}>
                                    <FormGroup className="mb-3" >
                                        <FormLabel>Lieu</FormLabel>
                                        <div className='inline_input'>
                                            <FormControl className='input-rond' type="text" id="lieu" placeholder="Lieu événement" name="lieu"  onChange={handleLocation} required={false}></FormControl>
                                        </div>
                                        <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div id="mapid">
                                <MapContainer
                                    center={center}
                                    zoom={13}
                                    scrollWheelZoom={true}
                                    style={{ height: "500px", width:"100%"}}
                                    >
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <LeafletgeoSearch />
                                </MapContainer>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showModalDateTime} onHide={handleCloseModalDateTime}>
                        <Modal.Body>
                            <Row>
                                <Col md={12} lg={2}>
                                    <FormGroup className="mb-3" >
                                        <FormLabel>Date</FormLabel>
                                        <FormControl className="input_bo" type="date" id="dateDebut" placeholder="Date début" name="dateDebut" onChange={handleDate} ></FormControl>
                                        <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                                    </FormGroup>
                                </Col>
                                <Col md={12} lg={2}>
                                    <FormGroup className="mb-3" >
                                        <FormLabel>Heure</FormLabel>
                                        <FormControl className="input_bo" type="time" id="heure" placeholder="Heure" name="heure" onChange={handleHeure} ></FormControl>
                                        <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                                    </FormGroup>
                                </Col>
                                <Col md={12} lg={2}>
                                    <Button className='bt_date' onClick={addDateTime}>valider</Button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                    
                </div>
            </div>
            <div id="step2" style={{ display: `${step === 2 ? "block" : "none"}` }} >
                <div className='col-md-12 col-lg-12'>
                    <div className='success_div'>
                        <FontAwesomeIcon icon={faCheckCircle} className='blue_check' ></FontAwesomeIcon>
                        <p className='title_pop'>Evénement crée ! </p>
                        <p>Nous vérifierons votre événement et vous répondriez le plus vite possible</p>
                        <Button className='btn_ok'>ok</Button>
                    </div>
                </div>
            </div>
            <div className='col-md-12 col-lg-12'>
                <Row>  
                    <Col md={12} lg={12} className="center">
                        <div className="bottom_txt"><Button className="btn_login" type="submit">Continue</Button></div>
                    </Col>
                </Row>
            </div>
            </Form>
        </Container>
        </>
    );
}
export default CreateEventPage;

function LeafletgeoSearch(props) {
    
    const map = useMap();
    
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
     
      const searchControl = new GeoSearchControl({
        provider,
        keepresult:false,
        marker: {
          icon
        }
      });

      map.addControl(searchControl);
      

      return () => map.removeControl(searchControl);
    }, [map]);

   
  
    return null;
}