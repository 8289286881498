/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import logo from './../../assets/images/logo_blue.png'
import { Col , Button } from 'react-bootstrap';
import fleche from '../../assets/images/arrow-left.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateBde } from '../../redux/signup/actions';


function JoinBdeSignupForm({ step , onPrev , onConfirm , onNext}) {

    const dispatch = useDispatch();

    const { bdes } = useSelector((state) => state.signup);
    const idBde = useSelector((state) => state.signup.formData.bde);
    const [bde, setBde] = useState(bdes.find((bde) => bde.Profile.id === idBde));
    const [steps, setSteps] = useState([0,1,2,3,4])

    const handlePrev = () => {
        dispatch(updateBde([]));
        onPrev(step)
    }

    const handleSkip = () => {
        onConfirm()
    }

    return (
        <>
            <div className="col-md-12 col-lg-12 dv_retour">
                <div className="col-md-12 col-lg-12 dv_retour">
                    <a className='black' onClick={handlePrev}><img src={fleche} alt="fleche gauche" /></a>
                    <a className='black droite' onClick={handleSkip}>Passer</a>
                    <ul className="inline_progress top30">
                    {steps.map((_, index) => (
                        <li key={index} className={step === index ? "current" : ""}>&nbsp;</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className="col-md-12 col-lg-12">
            <h2 className="titre_h2">Rejoindre un BDE</h2>
                <div className='img_bde' style={{backgroundImage:`url(${logo})`}}></div>
                <h3 className="titre_h3 center">{bde?.Profile?.fullName}</h3>
                <span className='bde_adresse'>{bde?.Profile?.adresse}</span>
                <p style={{textAlign:'center'}}>{bde?.Profile?.about}</p>
            </div>
            <div className='col-md-12 col-lg-12'>
                <Col md={12} lg={12} className='center'>
                    <div className='bottom_submit'>
                        <Button className="btn_login" onClick={onNext}>confirmer</Button>
                    </div>
                </Col>
            </div>
        </>   
    )
}
export default JoinBdeSignupForm;