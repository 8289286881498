import {
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCCESS,
    FETCH_CATEGORIES_FAILED,
} from "./types"
const initialState = {
  error: null,
  loading: false,
  categories: null,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CATEGORIES_SUCCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case FETCH_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
