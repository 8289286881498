/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Form , Button , Col, ListGroup } from "react-bootstrap";
import { searchBdes } from '../../redux/signup/actions'
import { useDispatch, useSelector } from "react-redux";
import fleche from '../../assets/images/arrow-left.svg';


function ChooseBdeSignupForm({ step , onPrev , onNext , onConfirm }) {

  const dispatch = useDispatch();
  const data = useSelector((state) => state.signup.bdes);

  const [steps, setSteps] = useState([0,1,2,3,4])
  const  [bdes,setBdes] = useState([])

  const handleChange = (e) => {
    const value = e.target.value;
    if(value.length > 0){
      dispatch(searchBdes(value));
    }else{
      setBdes([])
    }
  }

  const handlePrev = () => {
    onPrev(step);
  }

  useEffect(() => {
    setBdes(data)
  },[data])

  return (
    <>
      <div className="col-md-12 col-lg-12 dv_retour">
        <div className="col-md-12 col-lg-12 dv_retour">
          <a className='black' onClick={handlePrev}><img src={fleche} alt="fleche gauche" /></a>
          <ul className="inline_progress top30">
            {steps.map((_, index) => (
              <li key={index} className={step === index ? "current" : ""}>&nbsp;</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="col-md-12 col-lg-12">
        <h2 className="titre_h2">Choisissez votre BDE</h2>
        <p className="p_content">
          Sélectionnez le BDE lié à votre école. BDE vous fournira un code pour
          confirmation.
        </p>
        <p className="p_content">
          Rejoindre un BDE vous permettra de profiter pleinement de l’expérience
          Budease !
        </p>
      </div>

      <div className="col-md-12 col-lg-12 search-form">
        <Form.Control className="input-round" type="text" placeholder="Recherche BDE" onChange={handleChange} >
        </Form.Control>
        <Col md={12} lg={12} className="center">
            <div className='bottom_submit'>
                <Button className="btn_noborder"  onClick={()=>onConfirm()}>Complete This Later</Button>
            </div>
        </Col>
      </div>
      <br/>
      <div className="col-md-12 col-lg-12">
          <ListGroup>
            { bdes.map((bde,index) => (
                <ListGroup.Item key={index} onClick={() => onNext({ bde: bde.Profile.id })}>{bde?.Profile?.fullName}</ListGroup.Item>
            ))
            }
          </ListGroup>
      </div>
    </>
  );
}

export default ChooseBdeSignupForm;
