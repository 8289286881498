import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";

import reducers from "./redux/reducers";

const rootReducer = combineReducers({
  ...reducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const persistor = persistStore(store);

export { store, persistor };
