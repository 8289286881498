import { Container } from "react-bootstrap";

import logoBlue from "../assets/images/logo_blue.png";

function AuthLayout({ children }) {
  return (
    <>
      <Container fluid className="full">
        <div className="mobile_div">
          {children}
        </div>
      </Container>
    </>
  );
}

export default AuthLayout;
