/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import {  Col, Form , FormGroup , FormControl , Button } from 'react-bootstrap';
import confirmation from '../../assets/images/confirmation.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifiedCode } from '../../redux/signup/actions';
import AlertComponent from '../common/AlertComponent';
import errors from '../../helpers/Errors';
import fleche from '../../assets/images/arrow-left.svg';

function ConfirmBdeSignupForm({step,onPrev,onConfirm}){

    const dispatch = useDispatch();
    const { error,loading,codeVerified } = useSelector((state) => state.signup);
    
    const { bdes } = useSelector((state) => state.signup);
    const idBde = useSelector((state) => state.signup.formData.bde);
    const [bde, setBde] = useState(bdes.find((bde) => bde.Profile.id === idBde));
    const [steps, setSteps] = useState([0,1,2,3,4])
    const [codeBde,setCodeBde] = useState('');
    const [validated,setValidated] = useState(true);
    

    const handleCode = (code) => {
        setValidated(true);
        const codeRegex = /^[0-9]{0,4}$/; // Regex pour accepter uniquement les chiffres et une longueur maximale de 4
        if (codeRegex.test(code)) {
            setCodeBde(code);
        }
    };

    const onSubmit = () => {
        const codeRegex = /^[0-9]{4}$/;
        if (codeRegex.test(codeBde)) {
            setValidated(true);
            dispatch(verifiedCode(bde.id,codeBde));
        }else{
            setValidated(false);
        }
    }

    const handlePrev = () => {
        onPrev(step)
    }

    useEffect(() => {
        if(codeVerified){
            onConfirm();
        }
    },[codeVerified])

    return (
        <>
            <div className="col-md-12 col-lg-12 dv_retour">
                <div className="col-md-12 col-lg-12 dv_retour">
                    <a className='black' onClick={handlePrev}><img src={fleche} alt="fleche gauche" /></a>
                    <ul className="inline_progress top30">
                    {steps.map((_, index) => (
                        <li key={index} className={step === index ? "current" : ""}>&nbsp;</li>
                    ))}
                    </ul>
                </div>
            </div>
            <div className="col-md-12 col-lg-12">
                <h2 className="titre_h2">Confirmation</h2>
                <div className='img_confirmation center'><img alt='' src={confirmation}  /></div>
                <p className='center texte_16'>Saisissez le code de confirmation qui vous a été remis par votre Association BDE</p>
            </div>
            <div className='col-md-12 col-lg-12'>
                <Col md={12} lg={12}>
                    <FormGroup className="mb-3" >
                        <div className='inline_input'>
                            <FormControl className='input-code' type="text" id="codebde" placeholder="5 6 7 7" name="codebde" value={codeBde} onChange={(e) => handleCode(e.target.value)}   required={true}></FormControl>
                            { !validated && <span className='small text-danger'> Veuillez saisir un code de confirmation de 4 chiffres </span> }
                        </div>
                    </FormGroup>
                </Col>
                { error  &&
                    (
                        <Col md={12} lg={12}>
                            <AlertComponent show={true} duration={3000} variant="danger" text={errors[error]} />
                        </Col>
                    )
                }
                <Col md={12} lg={12} className="center">
                    <div className='bottom_submit'>  <Button className="btn_login" onClick={onSubmit}>Confirmer</Button></div>
                </Col>
            </div>
        </>
    )
}

export default ConfirmBdeSignupForm;