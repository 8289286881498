/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder";
import "esri-leaflet-geocoder";
import L from "leaflet";
import axios from "axios";

function MapSearchapp({ height ,  onOk , position , location }) {
  const markerRef = useRef(null);
  const mapRef = useRef(null);
  const circleRef = useRef(null);

  const createMarker = (map, center, name) => {
    if (!markerRef.current) {
      markerRef.current = L.marker(center, {
        icon: L.icon({
          iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
        }),
      }).addTo(map);
    }
    markerRef.current.setLatLng(center).update();
    markerRef.current
      .bindPopup(`${name}<br /><div class="btn-validate"><button id="validateButton">Valider</button><div>`)
      .openPopup();
    
    // validation
     const validateButton = markerRef.current.getPopup().getElement().querySelector("#validateButton");
     validateButton.addEventListener("click", () => {
        onOk(center, name);
     });
  };

  const createCircle = (map, center, radius, fillColor) => {
    if (!circleRef.current) {
      circleRef.current = L.circle(center, {
        radius: radius, // Spécifiez le rayon en mètres
        fillColor: fillColor, // Couleur de remplissage
        fillOpacity: 0.2, // Opacité du remplissage
      }).addTo(map);
    }
  };

  useEffect(() => {
    // INIT MAP
    const map = L.map(mapRef.current).setView(
      [45.269169177925754, -0.5231516014256281],
      12
    );
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    // GEOCODER
    const searchControl = L.Control.geocoder({
      defaultMarkGeocode: false,
    }).addTo(map);

    if (position && location) {
      createMarker(map, position, location);
      map.setView(position, 13);
    }

    searchControl.on("markgeocode", (e) => {
      const { center, name } = e.geocode;
      createMarker(map, center, name);
      // createCircle(map,center, 1000, "blue");
      map.setView(center, 13);
    });

    // PICKER ADRESSE
    map.on("click", function (e) {
      const { lat, lng } = e.latlng;
      axios
        .get(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
        )
        .then((res) => {
          const { display_name } = res.data;
          createMarker(map, [lat, lng], display_name);
        });
    });

    return () => {
      map.remove();
    };
  }, []);
  return <div ref={mapRef} style={{ height: height }}></div>;
}
export default MapSearchapp;
