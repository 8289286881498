/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useEffect , useState } from "react";
import ProfilSignupForm from "../../../../components/bde/ProfilSignupForm";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../redux/signup/actions";
import { signup, updateVerifiedMail } from "../../../../redux/signup/actions";
import UploadDocumentsForm from "../../../../components/bde/UploadDocumentsForm";
import { useNavigate } from "react-router-dom";


function BdeSignupPage() {
  const dispatch = useDispatch();
  let navigate = useNavigate();


  const signupState = useSelector((state) => state.signup);
  const { formData , success } = signupState;

  const [step, setStep] = useState(0);

  const StepComponents = [
    (props) => <ProfilSignupForm onNext={props.handleProfilSubmit} />,
    (props) => (
      <UploadDocumentsForm
        onPrev={props.onPrev}
        onNext={props.handleProfilSubmit}
        onConfirm={props.handleSubmit}
      />
    ),
  ];

  const onPrev = () => {
    if (step === 1) dispatch(updateVerifiedMail(false));
    setStep(step - 1);
  };

  const handleProfilSubmit = (data) => {
    dispatch(updateFormData({ ...formData, ...data }));
    setStep(1);
  };

  const handleSubmit = (data) => {
    dispatch(signup({ ...formData, files: [...data] }));
  };
  const [steps, setSteps] = useState(StepComponents);

  useEffect(() => {
    if(success) navigate('/inscription/success');
  },[success])


  return <>{steps[step]({ handleProfilSubmit, handleSubmit, onPrev })}</>;
}
export default BdeSignupPage;
