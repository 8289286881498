import ApiProvider from "../../helpers/ApiProvider";
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCCESS,
  FETCH_CATEGORIES_FAILED,
} from "./types";

export const fetchCategories = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CATEGORIES_REQUEST });

    const response = await ApiProvider.api.get("/categorie");

    if (response.data.success) {
      dispatch({
        type: FETCH_CATEGORIES_SUCCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: FETCH_CATEGORIES_FAILED,
        payload: response.data.error.error,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CATEGORIES_FAILED,
      payload: error.response?.data.error
        ? error.response?.data.error
        : error.message,
    });
  }
};
