import React, { useState, useRef, useEffect} from 'react';
import { useSelector , useDispatch } from "react-redux";
import fleche from '../../assets/images/arrow-left.svg';
import { faCheckCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Container, Row, Col, Form, FormControl, FormGroup, Button, FormLabel, Modal} from 'react-bootstrap';
import PriceFieldEvent from './PriceFieldEvent';

function PricesEventForm() {

    const [validated,setValidated] = useState(false);
    const [priceFields, setPriceFields] = useState([<PriceFieldEvent />]);

    const [showModalAudience, setShowModalAudience] = useState(false);

    const handleShowModalAudience = () => {
        setShowModalAudience(true);
    };
    
    const handleCloseModalAudience= () => {
        setShowModalAudience(false);
    };

    const handlePriceEvent = (e) => {
        e.preventDefault();
        console.log("NEXT =>");
        const form = e.currentTarget;
        if ( form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        
        } else {
            //popup audiance à afficher aussi 
            setShowModalAudience(true);
        }
        setValidated(true);
    };

    const addFieldPrice = (e) => {
        setPriceFields([...priceFields, <PriceFieldEvent />]);
    } 


    const publierEvent = (e) => {

    }

    return (
        <>
        <div className='col-md-12 col-lg-12 dv_retour'>
            <a className='black' href="/"><img src={fleche} alt="fleche gauche" /> Créer un événement</a>
            <ul className='inline_progress event'>
                <li className='current'>&nbsp;</li>
                <li className='current'>&nbsp;</li>
                <li className='current'>&nbsp;</li>
                <li>&nbsp;</li>
            </ul>
        </div>
        <div className='col-md-12 col-lg-12 conteneur'>
            <Form className='formPriceEvent' noValidate validated={validated} onSubmit={handlePriceEvent}>
                <Row>  
                    <Col md={12} lg={12}>
                        <h2 className="titre_h2">Tarifs et options</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        {priceFields.map((tarif, index) => (
                            <div key={index}>{tarif}</div>
                        ))}
                    </Col>
                   
                    <Col md={12} lg={12} className="center">
                        <Button className="btn_add" onClick={addFieldPrice} ><FontAwesomeIcon icon={faPlus} /> Ajouter une autre tarif</Button>
                    </Col>
             
                </Row>
                <Row>  
                    <Col md={12} lg={12} className="center">
                        <div className='top30'><Button className="btn_login" type="submit">Continue</Button></div>
                    </Col>
                </Row>
            </Form>
        </div>
        <Modal show={showModalAudience} onHide={handleCloseModalAudience}>
            <Modal.Body>
                <Row>
                    <Col md={12} lg={2}>
                        <h2 className="titre_h2 center">Publier l'événement</h2>
                        <p className='top30 center'>Souhaitez-vous publier cet événement?</p>
                    </Col>
                    <Col>
                        <div className="mb-3">
                            <Form.Check
                                label="seulement pour les étudiants de l'école"
                                name="bdestudent"
                                type="radio"
                                id="bdestudent"
                            />
                            <Form.Check
                                inline
                                label="Tout le monde"
                                name="public"
                                type="radio"
                                id="toutlemonde"
                            />
                        </div>

                    </Col>
                    <Col md={12} lg={2}>
                        <div className='groupe_inline_bouton top30'>
                            <Button className='btn_gris' >Pas maintenant</Button>
                            <Button className='btn_login' onClick={publierEvent}>Publier</Button>
                        </div>
                        
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        </>
    );
}
export default PricesEventForm;