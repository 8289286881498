/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

function AlertComponent({ text, show, variant, duration }) {
  const [showAlert, setShowAlert] = useState(show);

  useEffect(() => {
    setShowAlert(show);
  }, [show]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, duration);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Alert
      show={showAlert}
      variant={variant}
      onClose={() => setShowAlert(false)}
      dismissible
    >
      {text}
    </Alert>
  );
}

export default AlertComponent;
