/* eslint-disable import/no-anonymous-default-export */


const { REACT_APP_BASE_URL  } = process.env;

const host =  window.location.host; 

const baseUrl = (host.indexOf('localhost') === -1) ? REACT_APP_BASE_URL : "http://localhost:4041";

export default {
    version : "1.0.0",
    baseUrl,
    url:`${baseUrl}/api`
}