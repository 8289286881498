import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert, Form} from 'react-bootstrap';
import arrowLeft from '../images/arrow-left.svg';
import EventTarifDisplay from './EventTarifDisplay';


function EventReservationStudent(props) {


    return (
        <>
        <Container className='full'>
            <div className='col-md-12 col-lg-12 dv_retour content'>
                <a className='black' href="/"><img src={arrowLeft} alt="fleche gauche" /> Faire une réservation</a>
                <ul className='inline_progress event'>
                    <li className='current'>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                </ul>
                <h2 className='titre_h2'>Séléctionner les tarifs et options </h2>
                <p className='texte_20'>Date</p>
                <Form>
                    <Row>
                        <Col md={12} lg={12}>
                        <Form.Check type="radio" id="date_1">
                            <Form.Check.Input type="radio"  />
                            <Form.Check.Label className='label_check'>Lundi 06 Nov, 16h:00</Form.Check.Label>
                            <span className='legendlabel'>57/100 participants </span>
                        </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={12}>
                        <Form.Check type="radio" id="date_1">
                            <Form.Check.Input type="radio"  />
                            <Form.Check.Label className='label_check'>Mardi 07 Nov, 16h:00</Form.Check.Label>
                            <span className='legendlabel'>80/100 participants </span>
                        </Form.Check>
                        </Col>
                    </Row>
                    <Row>
                        <p className='separation'>&nbsp;</p>
                    </Row>
                    <Row>  
                        <EventTarifDisplay />
                    </Row>
                    <Row>
                        <Col md={12} lg={12} className="center">
                            <div className="bottom_submit_sign">
                            <Button className="btn_signup" type="submit">
                               Continuer
                            </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Container>
        </>
    )
}
export default EventReservationStudent;