import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import './ListEventStudentPage.css';
import club from '../images/nightclub.jpg';
import latino from '../images/latino.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import Swiper styles
import 'swiper/css';


function EventNearYouStudent (props) {


    return (
        <>
      
        <div className='list_event_nearyou content'>
            <h2 className='back_h2'>Près de vous</h2>
            <div className='carousel_event'>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={2}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide>
                        <div className='a_event'>
                            <a href="/event/001">
                            <img src={club} className='event_couv' />
                            <span className='a_date'>12 Aug - 16 Aug</span>
                            <div className='desc_event'>
                                <span className='title_event'>Les Misérables</span>
                                <span className='lieu_event'><FontAwesomeIcon icon={faMapMarkerAlt} /> Theatre de la Ville</span>
                                <span className='price_event'>21 &euro;</span><span className='price_reel'>18 &euro;</span>
                            </div>
                            </a>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='a_event'>
                            <img src={latino} className='event_couv' />
                            <span className='a_date'>12 Aug - 16 Aug</span>
                            <div className='desc_event'>
                                <span className='title_event'>Latino Club</span>
                                <span className='lieu_event'><FontAwesomeIcon icon={faMapMarkerAlt} /> Theatre de la Ville</span>
                                <span className='price_event'>21 &euro;</span><span className='price_reel'>18 &euro;</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='a_event'>
                            <img src={club} className='event_couv' />
                            <span className='a_date'>12 Aug - 16 Aug</span>
                            <div className='desc_event'>
                                <span className='title_event'>Les Misérables</span>
                                <span className='lieu_event'><FontAwesomeIcon icon={faMapMarkerAlt} /> Theatre de la Ville</span>
                                <span className='price_event'>21 &euro;</span><span className='price_reel'>18 &euro;</span>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                
            </div>
        </div>
    
        </>
    );
}
export default EventNearYouStudent;