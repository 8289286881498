/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fleche from "./images/arrow-left.svg";
import "./SignupPage.css";

import { Row, Col, Form, Button, Container } from "react-bootstrap";
import privileges from "../../../data/privileges";

function SignupPage(props) {
  const { privilege } = useParams();

  return <>{privileges[privilege]?.signupPage() || ''}</>;
}
export default SignupPage;
