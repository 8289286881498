/* eslint-disable import/no-anonymous-default-export */
import signup from "./signup/reducer";
import auth from "./auth/reducer";
import categorie from "./categories/reducer";
import event from './event/reducer';

export default {
    auth,
    signup,
    categorie,
    event
}