import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import './ListEventBdePage.css';
import logoToutes from '../images/toutes.svg';
import jouet from '../images/jouet.svg';
import musee from '../images/musee.svg';
import welfare from '../images/welfare.svg';



function TopBarEventBde (props) {

    return (
        <>
        <div className='top_bar'>
            <h2 className="texte_16">Activités</h2>
        </div>
        </>
    );
}
export default TopBarEventBde;