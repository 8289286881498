import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import tokenManager from "./helpers/TokenManager";
import { fetchUser } from "./redux/auth/actions";
import LoginPage from "./pages/public/Login/LoginPage";
import SignupPage from "./pages/public/signup/SignupPage";
import ChoosePrivilegePage from "./pages/public/signup/ChoosePrivilegePage";
import SuccesPage from "./pages/public/signup/SuccesPage";
import ConfirmPage from "./pages/public/signup/ConfirmPage";
import CreateEventPage from "./pages/public/event/CreateEventPage";
import ListEventStudentPage from "./pages/public/event/student/ListEventStudentPage";
import EventStudentMap from "./pages/public/event/student/EventStudentMap";
import EventDetailStudent from "./pages/public/event/student/EventDetailStudent";
import EventReservationStudent from "./pages/public/event/student/EventReservationStudent";
import EventListePage from "./pages/customer/bde/EventListePage";
import EventCreatePage from "./pages/customer/bde/EventCreatePage";
import BdeCreateEventPage from "./pages/public/event/bde/BdeCreateEventPage";
import ListEventBdePage from "./pages/public/event/bde/ListEventBdePage";
import MapSearchapp from "./pages/public/event/bde/MapSearch";

function PrivatePage({ page, allowedRoles, userRoles }) {
  const isAuthorized = allowedRoles.some((role) => userRoles.includes(role));

    return isAuthorized ? <>{page}</> : <></>;

}

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const token = tokenManager.getToken();
    if (token) {
      dispatch(fetchUser(token));
    }else {
      
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/inscription" element={<ChoosePrivilegePage />} />
        <Route path="/inscription/:privilege" element={<SignupPage />} />
        <Route path="/inscription/success" element={<SuccesPage />} />
        <Route path="/inscription/confirm/:token" element={<ConfirmPage />} />
        <Route
          path="/bde-event"
          element={
            <PrivatePage
              page={<ListEventBdePage />}
              allowedRoles={["BDE"]}
              userRoles={user?.privileges || []}
            />
          }
        />
        {/*<Route
          path="/bde-event"
          element={<ListEventBdePage />}
      />*/}
         {/*<Route
          path="/bde-create-event"
          element={
            <PrivatePage
              page={<EventCreatePage/>}
              allowedRoles={["BDE"]}
              userRoles={user?.privileges || []}
            />
          }
        />*/}
        <Route
          path="/bde-create-event"
          element={<BdeCreateEventPage />}
        />
        <Route
          path="/create-event"
          element={
            <PrivatePage
              page={<CreateEventPage />}
              allowedRoles={["ETUDIANT","BDE"]}
              userRoles={user?.privileges || []}
            />
          }
        />
        <Route
          path="/student-event"
          element={
            <PrivatePage
              page={<ListEventStudentPage />}
              allowedRoles={["ETUDIANT"]}
              userRoles={user?.privileges || []}
            />
          }
        />
        <Route
          path="/map-search"
          element = {
            <MapSearchapp></MapSearchapp>
          }
        >
        </Route>
        {/*<Route
          path="/student-event-map"
          element={
            <PrivatePage
              page={<EventStudentMap />}
              allowedRoles={["ETUDIANT"]}
              userRoles={user?.privileges || []}
            />
          }
        />*/}
        <Route
          path="/student-event-map"
          element={<EventStudentMap />}
        />
        <Route
          path="/event/:eventID"
          element={
            <PrivatePage
              page={<EventDetailStudent />}
              allowedRoles={["ETUDIANT"]}
              userRoles={user?.privileges || []}
            />
          }
        />
        <Route
          path="/reservation/:eventID"
          element={<EventReservationStudent />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
