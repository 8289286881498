/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Container, Col, Row, Button, Form, FormControl, FormLabel, FormGroup, Image, Spinner, ListGroup} from 'react-bootstrap';
import logoBlue from '../../assets/images/logo_blue.png';
import fleche from '../../assets/images/arrow-left.svg';
import uploadDocument from '../../assets/images/upload.svg';
import btnRemove from "../../assets/images/delete.svg";
import AlertComponent from '../common/AlertComponent';
import Utils from '../../helpers/Utils';
import { useDispatch, useSelector } from 'react-redux';
import errors from '../../helpers/Errors';


function UploadDocumentsForm({onPrev,onConfirm}) {

    const [documents, setDocuments] = useState([])

    const { error,loading,codeVerified } = useSelector((state) => state.signup);


    const handleSubmit = () => {
        onConfirm(documents)
    }

    const handlePrev = () => {
        onPrev()
    }

    const handleUpload = (e) => {
        const files = Array.from(e.target.files);
        setDocuments([...documents, ...files]);
    }

    const handleDelete = (index) => {
        const updatedDocuments = [...documents];
        updatedDocuments.splice(index, 1);
        setDocuments(updatedDocuments);
      };

    return (
        <>
            <Container fluid className="full flex_divider">
            <div className="second_div flex_start">
                <div className="col-md-12 col-lg-12 dv_retour">
                <a className="black leftop" onClick={handlePrev}>
                    <img src={fleche} alt="fleche gauche" />
                </a>
                <ul className="inline_progress">
                    <li className="width50" >&nbsp;</li>
                    <li className="current width50" >&nbsp;</li>
                </ul>
                <h2 className="titre_h2">Téléverser vos documents</h2>
                </div>
                <div className="col-md-12 col-lg-12 top20">
                    <Row>
                    <Col md={12} lg={12}>
                        <p className='p_content'>Veuillez télécharger l'un des documents suivants pour vérifier votre identité en tant que BDE.</p>
                        <ul className='ul_puce'>
                            <li>Statuts de l'association</li>
                            <li>Un relevé bancaire au nom de la BDE</li>
                            <li>Toute autre preuve que vous gérez un BDE</li>
                        </ul>
                    </Col>
                    <Col md={12} lg={12} className="center">
                        <input type="file" multiple name="profilePicture" id="profilePicture" style={{ display: 'none' }} onChange={handleUpload} />
                        <Button className="btn_upload_doc" type="button" onClick={() => document.getElementById('profilePicture').click()}>
                            <img src={uploadDocument} alt="profil" className='btn_profil' />
                            <p className='p_content center'>Téléverser les documents</p>
                        </Button>
                    </Col>
                    <Col>
                        <ListGroup>
                            {documents.map((file, index) => (
                                <ListGroup.Item key={index}>
                                    <span></span>{Utils.reduceFileName(file.name,25)}
                                    <button style={{background: 'transparent', border: 'none',marginLeft: '30px' }} className="btn_remove" onClick={() => handleDelete(index)}><img src={btnRemove} width={30} alt="profil" className='btn_profil' /></button>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    { error  &&
                        (
                            <Col md={12} lg={12}>
                                <AlertComponent show={true} duration={3000} variant="danger" text={errors[error]} />
                            </Col>
                        )
                    }
                    { 
                      documents.length>0  &&  <Col md={12} lg={12} className="center">
                            <div className="bottom_submit_sign">
                            <Button className="btn_signup" type="button" onClick={handleSubmit}>
                                Confirmer
                            </Button>
                            </div>
                        </Col>
                    }
                    </Row>
                </div>
            </div>
            </Container>
        </>
    )
}

export default UploadDocumentsForm;