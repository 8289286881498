import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ListEventStudentPage.css';
import latino from '../images/latino.jpg';
import arrowLeft from '../images/arrow-left-white.svg';
import favoris from '../images/favourite.svg';
import logo from '../images/logo-mobile.png';

function EventDetailStudent (props) {

    const divStyle = {
        backgroundImage:'url(' + latino + ')',
        backgroundSize: 'cover', // Pour redimensionner l'image pour couvrir le div
        backgroundPosition: 'center', // Position de l'image dans le div
        width: '100%', // Largeur du div
        height: '500px', // Hauteur du div
    };
    return (
        <>
        <Container className='full'>
            <div className='col-md-12 col-lg-12'>
                <div style={divStyle} className='couverture_detail'>
                    <a className='retour' href="/student-event"><img src={arrowLeft} /></a>
                    <div className='groupe_droie'>
                        <a className='favoris' href="/student-event"><img src={favoris} /></a>
                        <a className='partage' href="/student-event"><img src={favoris} /></a>
                        <a href="/reservation/event-001" className='btn_reserve'>Réserver</a>
                    </div>
                    <div className='groupe_info'>
                        <span className='title_event_detail'>Les Misérables</span>
                        <span className='date_event_detail'><FontAwesomeIcon icon={faCalendarAlt} /> 12 Aug - 16 Aug</span>
                    </div>
                </div>
                <div className='description_detail'>
                    <h2 className='titre_h2'>Détails</h2>
                    <span className='subtitle'>Lieu</span>
                    <span className='spancontent'>Theatre de la ville</span>
                    <span className='subtitle'>Prix</span>
                    <div className='groupe_price'>
                        <div className='prix_detail'>
                            <span className='barree'>21 &euro;</span>
                            <span className='prix_normal'>18 &euro;</span>
                        </div>
                        <span className='reduction_prix'>10% Reduction</span>
                    </div>
                    <span className='subtitle'>Description</span>
                    <p className="texte_14">A musical show, commonly referred to as a musical, is a theatrical performance that skillfully blends together elements such as songs, spoken dialogue, acting, and dance. These productions are known for their ability to convey compelling narratives that encompass a wide spectrum of emotions, ranging from joy and romance to frustration and sorrow. The roots of musicals can be traced back to various forms of 19th-century entertainment, including the music hall, comic opera, burlesque, vaudeville, variety shows, pantomime, and the minstrel show.</p>
                    <h2 className='titre_h2'>Réservation</h2>
                    <span className='texte_14'>Lundi, 06 Nov - 6h 00</span>
                    <span className='subtitle'>57/100 participants</span>
                    <span className='texte_14'>Mardi, 06 Nov - 6h 00</span>
                    <span className='subtitle'>57/100 participants</span>
                    <h2 className='titre_h2'>Sponsor</h2>
                    <div className='groupe_sponsor'>
                        <img className='rounded' src={logo} />
                        <span className='sponsor'>ABC Business Institute</span>
                    </div>
                </div>
            </div>
        </Container>
    
        </>
    );
}
export default EventDetailStudent;