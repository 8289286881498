import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, Form, FormLabel, FormGroup, Alert, Carousel, CarouselItem, FormControl} from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import loupe from '../images/loupe.png';
import filter from '../images/filter.svg';
import iconeMap from '../images/map.svg';
import iconeList from '../images/list.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import './ListEventBdePage.css';
import MenuBottomBde from './MenuBottomBde';
import TopBarEventBde from './TopBarEventBde';
import ListEventBdeTabs from './ListEventBdeTabs';



function ListEventBdePage (props) {

    return (
        <>
        <Container className='full'>
            <div className='col-md-12 col-lg-12'>
                <TopBarEventBde />
                <div className='col-md-12 col-lg-12 pad20'><h2 className='titre_h2'>Evénéments</h2></div>
                <div className='form_search'>
                    <Form className='form_student_activity'>
                        <FormGroup >
                            <div className='inline_input large'>
                                <img className='loupe' src={loupe} alt="loupe" />
                                <FormControl className='input-rond placeholdermarge' type="text" id="loupe" placeholder="recherche" name="loupe"  required={true}></FormControl>
                            </div>
                        </FormGroup>
                        <Button id="btn_filter"><img src={filter} alt="submit" /></Button>
                    </Form>
                </div>
               
            </div>
            <ListEventBdeTabs />
            <div className='icone_navigation_right'>
                <a className='btn_create_event' href="/bde-create-event"><FontAwesomeIcon icon={faPlusCircle} /></a>
            </div>
            <MenuBottomBde currentMenu='activity' />
        </Container>
        </>
    );
}
export default ListEventBdePage;