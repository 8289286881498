import Cookies from "js-cookie"
class TokenManager {

  setToken(token){
    Cookies.set('accesToken',token,{
      expires: 1
    })
  }

  // Function to get the token
  getToken() {
    try {
      return Cookies.get("accesToken");
    } catch (err) {
      this.clearToken();
      return null;
    }
  }

  // Function to clear the token (if needed)
  clearToken() {
    Cookies.remove("accesToken");
  }
}

// Export an instance of the class to use throughout the application
const tokenManager = new TokenManager();
export default tokenManager;
