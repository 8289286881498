/* eslint-disable import/no-anonymous-default-export */
export default {
    MISSING_INPUT: "Champ manquant",
    WRONG_IDENTIFIER: "Identifiant incorrect",
    SERVER_ISSUE: "Problème de serveur",
    INVALID_ACCOUNT: "Compte invalide",
    WRONG_EMAIL: "Adresse e-mail incorrecte",
    WRONG_PASSWORD: "Mot de passe incorrect",
    EMAIL_ALREADY_EXISTS: "Cette adresse e-mail existe déjà",
    UNAUTHORISED: "Non autorisé",
    INCORRECT_PASSWORD: "Mot de passe incorrect",
    USER_NOT_CONFIRMED: "Utilisateur non confirmé",
    INVALID_TOKEN: "Votre token n'est pas valide",
    EMAIL_NOT_SENT: "Impossible d'envoyer l'e-mail",
    UPLOAD_FAILED: "Échec du téléchargement",
    NOT_FOUND: "Non trouvé",
    INVALID_VALUE: "Valeur invalide",
    ALREADY_CONFIRMED: "Ce compte a déjà été confirmé",
    INVALID_CAPTCHA: "Captcha invalide",
    CODE_INVALID: "Code invalide",
  };
  