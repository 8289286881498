/* eslint-disable no-unused-vars */
import { Container , Button } from "react-bootstrap";
import congrat from "./images/congrats.png";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { faMailForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from "react";
function SuccesPage() {
  const navigate = useNavigate();
  const signupState = useSelector((state) => state.signup);

  const { success } = signupState;

  useEffect(() => {
    if (!success) navigate("/inscription");
  }, []);

  return (
    <Container>
      <div className="col-md-12 col-lg-12">
        <div className="success_div">
          <FontAwesomeIcon
            icon={faMailForward}
            className="blue_check"
          ></FontAwesomeIcon>
          <p className="title_pop">Confirmation de Compte</p>
          <p>
          Un email de confirmation a été envoyé à votre adresse email.
          Veuillez vérifier votre boîte de réception et suivre les instructions pour activer votre compte.
          </p>
          <p></p>
          <p>Merci!</p>
          <Button className="btn_ok" onClick={() => navigate("/")}>ok</Button>
        </div>
      </div>
    </Container>
  );
}

export default SuccesPage;
