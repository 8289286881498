import { Tab, Tabs } from "react-bootstrap";
import ListEventApprovedBde from "./ListEventApprovedBde";
import ListEventPublishedBde from "./ListEventPublishedBde";
import ListEventCancelledBde from "./ListEventCancelledBde";


function ListEventBdeTabs() {

    return (
        <>
        <div className='col-md-12 col-lg-12 pad20'>
            <Tabs
                defaultActiveKey="approved"
                id="tabs_bde"
                className="mb-3"
                >
                <Tab eventKey="approved" title="Approuvés">
                    <ListEventApprovedBde />
                </Tab>
                <Tab eventKey="published" title="Publiés">
                    <ListEventPublishedBde />
                </Tab>
                <Tab eventKey="cancelled" title="Annulés">
                    <ListEventCancelledBde />
                </Tab>
            </Tabs>
        </div>
        </>
    );
}
export default ListEventBdeTabs;