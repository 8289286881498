import latino from '../images/latino.jpg';
import { faLocationDot} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ListEventPublishedBde() {
    return (
        <>
        <div className="list_approved_event">
            <div className='a_recommended bde' >
                <div className='img_recommend'>
                    <img src={latino} className='couv_recommend' />
                </div>
                <div className='desc_recommend'>
                    <span className='title_recommend'>Les Misérables</span>
                    <span className='lieu_recommend'><FontAwesomeIcon icon={faLocationDot} /> Theatre de la Ville</span>
                    <span className='date_recommend'>12 Aug - 16 Aug</span>
                </div>
            </div>

            <div className='a_recommended bde'>
                <div className='img_recommend'>
                    <img src={latino} className='couv_recommend' />
                </div>
                <div className='desc_recommend'>
                    <span className='title_recommend'>Les Misérables</span>
                    <span className='lieu_recommend'><FontAwesomeIcon icon={faLocationDot} /> Theatre de la Ville</span>
                    <span className='date_recommend'>12 Aug - 16 Aug</span>
                </div>
            </div>
        </div>
        </>
    );
}
export default ListEventPublishedBde;