/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Row, Col, Form, FormControl, FormGroup, Button, FormLabel, Alert} from 'react-bootstrap';
import logo from './images/logo.png';
import logoBlue from './images/logo_blue.png';
import google from './images/google.png';
import facebook from './images/facebook.png';
import eyeOpen from './images/eye.svg';
import eyeClosed from './images/eye-closed.svg';
import './LoginPage.css';
import AlertComponent from '../../../components/common/AlertComponent';
import { useDispatch, useSelector } from 'react-redux';
import errors from '../../../helpers/Errors';
import { authRequest } from '../../../redux/auth/actions';
import privileges from '../../../data/privileges';

function LoginPage (props) {
    const dispatch = useDispatch();
    const authState = useSelector((state) => state.auth);
    const { user ,  success, loading, error } = authState;

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validated, setValidated] = useState(false);
    const [eyePassword, setEyePassword] = useState(eyeClosed);
    const [eyeActive, setEyeActive] = useState(true);

    const [captchaToken, setCaptchaToken] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if ( form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            dispatch(authRequest(email,password))   
        }
        setValidated(true);
    };


    const showPassword = (e) => {
        setEyeActive(!eyeActive);
        if (eyeActive) {
            setEyePassword(eyeOpen);
        } else {
            setEyePassword(eyeClosed);
        }
        
    };

    useEffect(() => {
        if(user){
            const role = user.privileges[0].toLowerCase();
            navigate(privileges[role].mainPage);
        }
    },[user])

    return (
        <>
        <Container fluid className='flex_divider full'>
            <div className='mobile_div'>
                <div className='col-md-12 col-lg-12'>
                    <div className='div_logo_mobile'>
                        <img src={logo} alt="logo Budease" />
                        <span className='text_logo'>Budease</span>
                    </div>
                    <h2 className="center titre_h2">Se connecter</h2>
                </div>
                <div className='col-md-12 col-lg-12 '>
                <Form className='formLogin' noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row>
                        <Col md={12} lg={12}>
                            <FormGroup className="mb-3" >
                                <FormLabel>E-mail</FormLabel>
                                <div className='inline_input'>
                                    <FormControl className='input-rond' type="email" id="email" placeholder="j.dupont@residence.com" name="email" value={email} onChange={(e) => setEmail(e.target.value)}   required={true}></FormControl>
                                </div>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                            </FormGroup>
                        </Col>

                        <Col md={12} lg={12}>
                            <FormGroup className="mb-3" >
                                <FormLabel>Mot de passe</FormLabel>
                                <div className='inline_input maso'>
                                    <FormControl className='input-rond' type={eyeActive ? "text" : "password"}  id="password" placeholder="..........." name="password" value={password} onChange={(e) => setPassword(e.target.value)}  required={true}></FormControl>
                                    <Button onClick={showPassword} className='btn_maso'><img alt='' src={eyePassword} className='eye_icon' /></Button>
                                </div>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                            </FormGroup>
                        </Col>

                        { error  &&
                            (
                                <Col md={12} lg={12}>
                                    <AlertComponent show={true} duration={3000} variant="danger" text={errors[error]} />
                                </Col>
                            )
                        }

                        <Col md={12} lg={12} className="center">
                            <Button className="btn_login" type="submit">se connecter</Button>
                        </Col>

                        <Col md={12} lg={12} className="center">
                            <p className='txt_or'>OU</p>
                        </Col>

                        <Col md={12} lg={12} className="center">
                            <Button className="btn_google" type="submit"><img alt='' src={google} className='logo_btn' />Continuer avec Google</Button>
                        </Col>

                        <Col md={12} lg={12} className="center">
                            <Button className="btn_facebook" type="submit"><img alt='' src={facebook} className='logo_btn' />Continuer avec Facebook</Button>
                        </Col>
                    </Row>
                </Form>
                </div>
                <div className='col-md-12 col-lg-12'>
                    <Col md={12} lg={12} className="center">
                        <div className='bottom_button'>Vous n'avez pas de compte ? <a className='link' href="/inscription">S'inscrire</a> </div>
                    </Col>
                </div>
            </div>
        </Container>
        </>
    );
}
export default LoginPage;