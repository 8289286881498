import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logoMobile from '../images/logo-mobile.png';
import logoOffre from '../images/offre.png';
import logoSocial from '../images/social.png';
import logoMessage from '../images/message.png';
import logoAccount from '../images/account.png';
import './ListEventBdePage.css';

function MenuBottomBde (props) {


    return (
        <>
      
        <div className='menu_bottom_mobile'>
            <ul className='item_menu'>
                <li className={ (props.currentMenu   == "activity") ? "active":""}>
                    <a href="/bde-event">
                    <img src={logoMobile} />
                    <span>Activités</span>
                    </a>
                </li>
                <li>
                    <img src={logoOffre} />
                    <span>Offres</span>
                </li>
                <li>
                    <img src={logoSocial} />
                    <span>Sociaux</span>
                </li>
                <li>
                    <img src={logoAccount} />
                    <span>Compte</span>
                </li>
            </ul>
        </div>
    
        </>
    );
}
export default MenuBottomBde;