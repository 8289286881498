import axios from "axios";
import apiConfig from "../config/api.config";
import tokenManager from "./TokenManager";

class ApiProvider {
  static api;

  static init(token) {
    ApiProvider.api = axios.create({
      baseURL: apiConfig.url,
    });
    if (token) {
      ApiProvider.api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
  }
}

const token = tokenManager.getToken();
ApiProvider.init(token);
export default ApiProvider;
