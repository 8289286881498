class Utils {
    static formatDateToText(dateString) {
        const months = [
          "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", 
          "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
        ];
      
        const date = new Date(dateString);
        const dayNumber = date.getUTCDate();
        const monthName = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();
      
        const formattedDate = `${dayNumber} ${monthName} ${year}`;
      
        return formattedDate;
  }

  static reduceFileName(fileName, maxLength) {
    if (fileName.length <= maxLength) {
      return fileName;
    }
  
    const extension = fileName.split('.').pop(); // Obtenir l'extension du fichier
    const fileNameWithoutExtension = fileName.slice(0, -(extension.length + 1)); // Retirer l'extension
  
    const reducedFileNameLength = maxLength - extension.length - 3; // -3 pour les trois points de suspension
    const reducedFileName = fileNameWithoutExtension.slice(0, reducedFileNameLength);
  
    return `${reducedFileName}...${extension}`;
  }
      
}

export default Utils;