import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import './ListEventStudentPage.css';
import logoToutes from '../images/toutes.svg';
import jouet from '../images/jouet.svg';
import musee from '../images/musee.svg';
import welfare from '../images/welfare.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';


function TopBarEventStudent (props) {

    return (
        <>
        <div className='top_bar'>
            <h2 className="texte_16">Activités</h2>
            <div className='carousel_categorie'>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={7}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide><img src={logoToutes} /></SwiperSlide>
                    <SwiperSlide><img src={jouet} /></SwiperSlide>
                    <SwiperSlide><img src={musee} /></SwiperSlide>
                    <SwiperSlide><img src={welfare} /></SwiperSlide>
                    <SwiperSlide><img src={musee} /></SwiperSlide>
                    <SwiperSlide><img src={jouet} /></SwiperSlide>
                    <SwiperSlide><img src={musee} /></SwiperSlide>
                    <SwiperSlide><img src={welfare} /></SwiperSlide>
                    <SwiperSlide><img src={musee} /></SwiperSlide>
                    <SwiperSlide><img src={jouet} /></SwiperSlide>
                </Swiper>
            </div>
        </div>
        </>
    );
}
export default TopBarEventStudent;