/* eslint-disable no-unused-vars */
import {
    VERFIED_MAIL,
    VERFIED_MAIL_SUCCESS,
    VERFIED_MAIL_FAILURE,
    FETCH_INTERETS,
    FETCH_INTERETS_SUCCESS,
    FETCH_INTERETS_FAILURE,
    SEARCH_BDES,
    SEARCH_BDES_SUCCESS,
    SEARCH_BDES_FAILURE,
    VERIFIED_CODE,
    VERIFIED_CODE_SUCCESS,
    VERIFIED_CODE_FAILURE,
    UPDATE_VERIFIED_MAIL,
    UPDATE_FORM_DATA,
    UPDATE_BDE,
    USER_SIGNUP_REQUEST,
    USER_SIGNUP_SUCCESS,
    USER_SIGNUP_FAILURE,
    USER_CONFIRM_REQUEST,
    USER_CONFIRM_SUCCESS,
    USER_CONFIRM_FAILURE
} from "./types"

const initialState = {
    formData: null,
    emailVerified: false,
    bdes : [],
    interets: [],
    codeVerified: false,
    loading: false,
    error: null,
    success: false
}


export default function rootReducer(state = initialState, action) {
    switch (action.type) {
        case VERFIED_MAIL:
            return {
                ...state,
                loading: true,
                emailVerified: false,
                error: null
            }
        case VERFIED_MAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                emailVerified: true,
                error: null
            }
        case VERFIED_MAIL_FAILURE:
            return {
                ...state,
                loading: false,
                emailVerified: false,
                error : action.payload
            }
        case FETCH_INTERETS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case FETCH_INTERETS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                interets: action.payload
            }
        case FETCH_INTERETS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.playload
            }
        case SEARCH_BDES:
            return {
                ...state,
                loading: true,
                error: null
            }
        case SEARCH_BDES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                bdes: action.payload
            }
        case SEARCH_BDES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.playload
            }
        case VERIFIED_CODE:
            return {
                ...state,
                loading: true,
                codeVerified: false,
                error: null
            }
        case VERIFIED_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                codeVerified: true,
                error: null
            }
        case VERIFIED_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                codeVerified: false,
                error : action.payload
            }
        case UPDATE_VERIFIED_MAIL:
            return {
                ...state,
                emailVerified: action.payload
            }
        case UPDATE_FORM_DATA:
            return {
                ...state,
                formData : action.payload
            }
        case UPDATE_BDE:
            return {
                ...state,
                bdes : action.payload
            }
        case USER_SIGNUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: false
            }
        case USER_SIGNUP_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true
            }
        case USER_SIGNUP_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error : action.payload
            }
        case USER_CONFIRM_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: false
            }
        case USER_CONFIRM_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                success: true
            }
        case USER_CONFIRM_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error : action.payload
            }
        default:
            return state
    }
}   