/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import AuthLayout from "../../../../Layouts/AuthLayout";
import ProfilSignupForm from "../../../../components/etudiant/ProfilSignupForm";
import InterestsSignupForm from "../../../../components/etudiant/InterestsSignupForm";
import ChooseBdeSignupForm from "../../../../components/etudiant/ChooseBdeSignupForm";
import fleche from '../images/arrow-left.svg';
import JoinBdeSignupForm from "../../../../components/etudiant/JoinBdeSignupForm";
import ConfirmBdeSignupForm from "../../../../components/etudiant/ConfirmBdeSignupForm";
import { useNavigate } from "react-router-dom";
import { signup, updateVerifiedMail } from "../../../../redux/signup/actions";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../../redux/signup/actions";
function StudentSignupPage() {

  const dispatch = useDispatch();

  let navigate = useNavigate();

  const signupState = useSelector((state) => state.signup);

  const { formData , success } = signupState;

  const [step, setStep] = useState(0); 

  const handleProfilSubmit = (data) => {
    dispatch(updateFormData({...formData,...data}));
    setStep(1);
  };

  const handleInterestsSubmit = (data) => {
    dispatch(updateFormData({...formData,...data}));
    setStep(2);
  };

  const handleBdesSelected = (bde) => {
    dispatch(updateFormData({...formData,...bde}));
    setStep(3);
  }

  const handleConfirmBde = () => {
    dispatch(updateFormData({...formData,confirmBde:true}));
    setStep(4);
  }

  const handleSubmit = () => {
      dispatch(signup(formData));
  }

  const onPrev = (step) => {
    if(step === 1) dispatch(updateVerifiedMail(false)); 
    setStep(step-1);
  }

  const skip = () => {
    
  }

  useEffect(() => {
    if(success) navigate('/inscription/success');
  },[success])

  const StepComponents = [
    (props) => <ProfilSignupForm step={props.step} onNext={props.handleProfilSubmit} />,
    (props) => <InterestsSignupForm step={props.step} onPrev={props.onPrev} onNext={props.handleInterestsSubmit}/>,
    (props) => <ChooseBdeSignupForm step={props.step} onConfirm={props.handleSubmit} onPrev={props.onPrev}  onNext={props.handleBdesSelected}></ChooseBdeSignupForm>,
    (props) => <JoinBdeSignupForm step={props.step}  onConfirm={props.handleSubmit} onPrev={props.onPrev}  onNext={props.handleConfirmBde}></JoinBdeSignupForm>,
    (props) => <ConfirmBdeSignupForm step={props.step} onPrev={props.onPrev}  onConfirm={props.handleSubmit} ></ConfirmBdeSignupForm>,
  ];

  const [steps, setSteps] = useState(StepComponents);



  return (
    <AuthLayout>
      {steps[step]({onPrev,handleProfilSubmit,handleInterestsSubmit,handleBdesSelected,handleConfirmBde,handleSubmit,step})}
    </AuthLayout>
  );
}

export default StudentSignupPage;
