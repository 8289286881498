import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_FAILURE,
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    UPDATE_USER
} from "./types"

const initialState = {
    error: null,
    loading: false,
    success : false,
    user : null
}

export default function rootReducer(state = initialState,action) {
    switch (action.type) {
        case AUTH_REQUEST:
            return{
                ...state,
                error: null,
                loading: true, 
                success: false
            }
        case AUTH_SUCCESS:
            return{
                ...state,
                error: null,
                loading: false,
                success: true,
                user : action.payload
            }
        case AUTH_FAILURE:
            return{
                ...state,
                error: action.payload,
                loading: false,
                success: false
            }
        case FETCH_USER:
            return{
                ...state,
                error: null,
                loading: true,
                success: false
            }
        case FETCH_USER_SUCCESS:
            return{
                ...state,
                error: null,
                loading: false,
                success: true,
                user : action.payload
            }
        case FETCH_USER_FAILURE:
            return{
                ...state,
                error: action.payload,
                loading: false,
                success: false
            }
        case UPDATE_USER:
            return{
                user : action.payload
            }
        default:
            return state
    }
}