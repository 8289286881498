import { useEffect , useState } from "react";
import DetailEventForm from "../../../../components/bde/DetailEventForm";
import { Container } from "react-bootstrap";
import DateLocationEventForm from "../../../../components/bde/DateLocationEventForm";
import PricesEventForm from "../../../../components/bde/PricesEventForm";
import { useDispatch } from "react-redux";
import { updateFormData } from "../../../../redux/event/actions";

function BdeCreateEventPage() {
    
    const dispatch = useDispatch();
    const [step, setStep] = useState(1); 

    const handleSubmitDetailEvent = (data) => {
        dispatch(updateFormData({...data}));
        setStep(2);
    }

    const onPrev = () => {
        setStep(step-1);
    }

    return(
        <>
        <Container fluid>
            { step ===1 && <DetailEventForm next={handleSubmitDetailEvent} />}
            { step ===2 && <DateLocationEventForm prev={onPrev} /> }
            {/* <PricesEventForm /> */}
        </Container>
        </>
    );
}
export default BdeCreateEventPage;