/* eslint-disable import/no-anonymous-default-export */
import LoginPage from "../pages/public/Login/LoginPage";
import BdeSignupPage from "../pages/public/signup/bde/BdeSignupPage";
import StudentSignupPage from "../pages/public/signup/etudiant/StudentSignupPage";

export default {
  etudiant: {
    signupPage: () => <StudentSignupPage />,
    mainPage: '/student-event',
    defaultPage: () => <LoginPage/>
  },
  bde: {
    signupPage: () => <BdeSignupPage/>,
    mainPage: '/bde-event',
    defaultPage: () => <LoginPage/>
  }
};
