export const privileges = [
    {
        path : "etudiant",
        value : "ETUDIANT",
        label : "Je suis un(e) étudiant(e)",
        disabled : false,
        defaultChecked : true
    },
    {
        path : "bde",
        value : "BDE",
        label : "Je voudrai créer un BDE",
        disabled : false
    },
    {
        path : "entreprise",
        value : "ENTREPRISE",
        label : "Je suis une Entreprise",
        disabled : true
    }
]