/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect} from 'react';
import { useSelector , useDispatch } from "react-redux";
import fleche from '../../assets/images/arrow-left.svg';
import { faAddressBook, faAddressCard, faCheckCircle, faMapLocation, faMapLocationDot, faMapMarked, faMapMarkerAlt, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Container, Row, Col, Form, FormControl, FormGroup, Button, FormLabel, Modal} from 'react-bootstrap';
import { Map, MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";


import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import icon from "./constants";
import MapSearchapp from '../common/MapSearch';

function DateLocationEventForm({next,prev}) {

    const mapRef = useRef(null);

    const [validated,setValidated] = useState(false);
    const [formData, setFormData] = useState();
    const [arrDateEvent, setArrDateEvent] = useState([]);
    const [showModalDateTime, setShowModalDateTime] = useState(false);
    const [currentDateTime, setCurrentDateTime] = useState({});
    const [location, setLocation] = useState(null);
    const [position, setPosition] = useState(null);
    const [showModaLocation, setShowModaLocation] = useState(false);
    const center = [45.269169177925754, -0.5231516014256281];


    const [detailEventData, setDetailEventData] = useState({
        eventName:formData?.eventName || "",
        eventCategory:formData?.eventCategory || "",
        eventDescription : formData?.eventDescription  || "" ,
        eventParticipant : formData?.eventParticipant  || "" ,
    });


    const handleShowModalDateTime = () => {
        setShowModalDateTime(true);
    };
    
    const handleCloseModalDateTime = () => {
        setShowModalDateTime(false);
    };

    const handleShowModaLocation = () => {
        setShowModaLocation(true);
    };
    
    const handleCloseModaLocation = () => {
        setShowModaLocation(false);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetailEventData({
          ...detailEventData,
          [name]: value,
        });
    };

   
    const handeDetailEvent = (e) => {

    };

    const handlePickAdresse = (position,name) => {
        setPosition(position);
        setLocation(name);
        setShowModaLocation(false);
    }

    const handleDate = (event) => {
        console.log(event);
        currentDateTime.date = event.target.value;
    };

    const handleHeure = (event) => {
        console.log(event);
        currentDateTime.heure = event.target.value;
    };

    const addDateTime = (event) => {
        let tempsObjetDate = {date:currentDateTime.date, heure:currentDateTime.heure};
        setShowModalDateTime(false);
        setArrDateEvent([...arrDateEvent, tempsObjetDate]);
    };

    useEffect(() => {
       
    },[])

    return (
        <>
        <div className='col-md-12 col-lg-12 dv_retour'>
            <a className='black' onClick={prev}><img src={fleche} alt="fleche gauche" /></a>
            <ul className='inline_progress event'>
                <li>&nbsp;</li>
                <li className='current'>&nbsp;</li>
                <li>&nbsp;</li>
            </ul>
        </div>
        <div className='col-md-12 col-lg-12 conteneur'>
            <Form className='formDateLocationEvent' noValidate validated={validated} onSubmit={handeDetailEvent}>
                <Row>
                    <h2 className="titre_h2">Adresse</h2>
                    <Col md={12} lg={12}>
                        <Form.Group className="mb-3" >
                            <Form.Control className="input-round input-adresse" as="textarea" id="adresse" name="adresse" placeholder="Adresse" rows={8}  required={true}/>
                            <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>  
                    <Col md={12} lg={12}>
                        {location && <p className='lieu'> <FontAwesomeIcon icon={faMapMarkerAlt} /> {location}</p>}
                    </Col>
                </Row>
                <Row>  
                    <Col md={12} lg={12} className="center">
                        <Button className="btn_add" onClick={handleShowModaLocation} ><FontAwesomeIcon icon={faPlus} /> { location ? "Modifier le position " : "Ajouter un position" }</Button>
                    </Col>
                </Row>
                <Row>  
                    <Col md={12} lg={12}>
                        <h2 className="titre_h2">Date et heure</h2>
                        <ul className='ul_lieu'>
                            {arrDateEvent.map((element, index) => (
                                <li key={index}>{element.date} à {element.heure}</li>
                            ))}
                        </ul>
                    </Col>
                </Row>
                <Row>  
                    <Col md={12} lg={12} className="center">
                        <Button className="btn_add" onClick={handleShowModalDateTime} ><FontAwesomeIcon icon={faPlus} /> Ajouter la date et l'heure</Button>
                    </Col>
                </Row>
                <Row>  
                    <Col md={12} lg={12} className="center">
                        <div className='top30'><Button className="btn_login" type="submit">Continue</Button></div>
                    </Col>
                </Row>
            </Form>
        </div>
        <Modal show={showModalDateTime} onHide={handleCloseModalDateTime}>
            <Modal.Body>
                <Row>
                    <Col md={12} lg={2}>
                        <FormGroup className="mb-3" >
                            <FormLabel>Date</FormLabel>
                            <FormControl className="input_bo" type="date" id="dateDebut" placeholder="Date début" name="dateDebut" onChange={handleDate} ></FormControl>
                            <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                        </FormGroup>
                    </Col>
                    <Col md={12} lg={2}>
                        <FormGroup className="mb-3" >
                            <FormLabel>Heure</FormLabel>
                            <FormControl className="input_bo" type="time" id="heure" placeholder="Heure" name="heure" onChange={handleHeure} ></FormControl>
                            <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                        </FormGroup>
                    </Col>
                    <Col md={12} lg={2}>
                        <Button className='bt_date' onClick={addDateTime}>valider</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        <Modal show={showModaLocation} onHide={handleCloseModaLocation}>
            <Modal.Header closeButton>
            <Modal.Title>Ajouter un lieu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="mapid">
                   <MapSearchapp position={position} location={location} onOk={handlePickAdresse} height={"600px"}></MapSearchapp>
                </div>
            </Modal.Body>
        </Modal>
        </>
    );
}
export default DateLocationEventForm;
