/* eslint-disable no-unused-vars */
import {
    UPDATE_VERIFIED_MAIL,
    VERFIED_MAIL,
    VERFIED_MAIL_SUCCESS,
    VERFIED_MAIL_FAILURE,
    FETCH_INTERETS,
    FETCH_INTERETS_SUCCESS,
    FETCH_INTERETS_FAILURE,
    SEARCH_BDES,
    SEARCH_BDES_SUCCESS,
    SEARCH_BDES_FAILURE,
    VERIFIED_CODE,
    VERIFIED_CODE_SUCCESS,
    VERIFIED_CODE_FAILURE,
    UPDATE_FORM_DATA,
    UPDATE_BDE,
    USER_SIGNUP_REQUEST,
    USER_SIGNUP_SUCCESS,
    USER_SIGNUP_FAILURE,
    USER_CONFIRM_REQUEST,
    USER_CONFIRM_SUCCESS,
    USER_CONFIRM_FAILURE
} from "./types"
import ApiProvider from '../../helpers/ApiProvider';
import { updateUser } from "../auth/actions";
import tokenManager from "../../helpers/TokenManager";

export const updateVerifiedMail = ( value ) => async (dispatch) => {
    dispatch({ type: UPDATE_VERIFIED_MAIL , payload : value });
}

export const updateFormData = ( data ) => async (dispatch) => {
    dispatch({
        type: UPDATE_FORM_DATA,
        payload: data
    })
}

export const updateBde = ( data ) => async (dispatch) => {
    dispatch({
        type: UPDATE_BDE,
        payload: data
    })
}

export const verfiedMail = (email) => async (dispatch) => {
    try {
        dispatch({ type : VERFIED_MAIL });

        const response = await ApiProvider.api.post('/user/verify-email', { email },{
            headers: {
              'Content-Type': 'application/json',
            },
          });
        if (response.data.success) {
            dispatch({ type: VERFIED_MAIL_SUCCESS}); 
        }else {
            dispatch({ type: VERFIED_MAIL_FAILURE, payload: response.data.error.error });
        }

    } catch (error) {
        dispatch({
            type: VERFIED_MAIL_FAILURE,
            payload: error.response?.data.error
                ? error.response?.data.error
                : error.message,
        });
    } 
} 

export const fetchInterets = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_INTERETS});
        const response = await ApiProvider.api.get('/tag/tags');

        if (response.data.success) {
            dispatch({
                type: FETCH_INTERETS_SUCCESS,
                payload: response.data.data,
            });
        } else {
            dispatch({
                type: FETCH_INTERETS_FAILURE,
                payload: response.data.error.error,
            });
        }

    } catch (error) {
        dispatch({
            type: FETCH_INTERETS_FAILURE,
            payload: error.response?.data.error ? error.response?.data.error : error.message
        })
    }
}

export const searchBdes = (value) => async (dispatch) => {
    try {
        dispatch({ type: SEARCH_BDES })

        const response = await ApiProvider.api.get(`/user/bde/search/${value}`);

        if (response.data.success) {
            dispatch({
                type: SEARCH_BDES_SUCCESS,
                payload: response.data.data,
            });
        }else {
            dispatch({
                type: SEARCH_BDES_FAILURE,
                payload: response.data.error.error,
            });
        }

    } catch (error) {
        dispatch({
            type: SEARCH_BDES_FAILURE,
            payload: error.response?.data.error
              ? error.response?.data.error
              : error.message,
        });
    }
}

export const verifiedCode = (id,code) => async (dispatch) => {
    try {
        dispatch({ type: VERIFIED_CODE })

        const response = await ApiProvider.api.post('/user/bde/verify', { id , code },{
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.data.success) {
            dispatch({
                type: VERIFIED_CODE_SUCCESS,
            });
        }else{
            dispatch({
                type: VERIFIED_CODE_FAILURE,
                payload: response.data.error.error,
            });
        }

    } catch (error) {
        dispatch({
            type: VERIFIED_CODE_FAILURE,
            payload: error.response?.data.error
              ? error.response?.data.error
              : error.message,
        }); 
    }
}

export const signup = (data) => async (dispatch) => {
    try {
        console.log(data);
        dispatch({ type: USER_SIGNUP_REQUEST });

        const formData = new FormData();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if(Array.isArray(data[key]) && key !== 'files'){
                    formData.append(key, JSON.stringify(data[key]));
                }else if ( data[key] && key === 'profilePicture'  ){
                    formData.append('documents', data[key]);
                    formData.append('profil', true);
                }else if(key === 'files' ){
                    for (const file of data[key]){
                        formData.append('documents', file);
                    }
                }else{
                    formData.append(key, data[key]);
                }
            }
        }

        const response = await ApiProvider.api.post('/user/register',formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        if (response.data.success) {
            console.log("succes");
            dispatch({ type: USER_SIGNUP_SUCCESS });
        } else {
            console.log("error");
            dispatch({ type: USER_SIGNUP_FAILURE, payload: response.data.error.error });
        }

    } catch (error) {
        console.log("error exception")
        dispatch({
            type: USER_SIGNUP_FAILURE,
            payload: error.response?.data.error
        })
    }
}

export const confirmAccount = (token) => async (dispatch) => {
    try {
        dispatch({ type: USER_CONFIRM_REQUEST });

        const response = await ApiProvider.api.post('/user/confirm-account', { token } , {
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.data.success) {
            dispatch({ type: USER_CONFIRM_SUCCESS });

            const { accessToken, data } = response.data.data;

            tokenManager.setToken(accessToken);

            dispatch(updateUser(data));
        } else {
            dispatch({ type: USER_CONFIRM_FAILURE, payload: response.data.error.error });
        } 
    } catch (error) {
        dispatch({
            type: USER_CONFIRM_FAILURE,
            payload: error.response?.data.error
        })       
    }
}
