import React, { useState, useRef} from 'react';
import {Container, Row, Col, Button, FormLabel, Alert} from 'react-bootstrap';
import './ListEventStudentPage.css';
import club from '../images/nightclub.jpg';
import latino from '../images/latino.jpg';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MapContainer, TileLayer, Popup, useMapEvents, useMap, Marker} from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
import MenuBottomStudent from './MenuBottomStudent';
import TopBarEventStudent from './TopBarEventStudent';
import pin from "../images/jouet.svg";
import L from 'leaflet';

function EventStudentMap (props) {

    const center = [51.49,-0.48];
    const customMarkerIcon = new L.Icon({
        iconUrl: pin,
        iconSize: [32, 32], // Dimensions de l'icône (largeur x hauteur)
        iconAnchor: [16, 32], // Point d'ancrage de l'icône par rapport à sa position (en bas au centre)
    });

    const events = [
        {
            id:1,
            name:"Les misérables",
            lat:51.49, 
            lon:-0.48,
            lieu: "Theatre Paris",
            price: "50"
           
        },
        {
            id:2,
            name:"NIGHT SHOW",
            lat:51.49, 
            lon:-0.28,
            lieu: "Theatre Paris",
            price: "50"
            
        },
        {
            id:3,
            name:"GIRL SHOW ",
            lat:51.49, 
            lon:-0.38,
            lieu: "Theatre Paris",
            price: "50"
            
        },
    ];

    return (
        <>
        <Container className='full'>
            <div className='col-md-12 col-lg-12'>
                <TopBarEventStudent />
                <div className='list_event_map'>
                    <MapContainer
                        center={center}
                        zoom={9}
                        scrollWheelZoom={true}
                        style={{ height: "655px", width:"100%"}}
                        >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {events.map(marker => (
                            <Marker key={marker.id} position={[marker.lat, marker.lon]} icon={customMarkerIcon}>
                                <Popup>
                                    <div className='a_recommended marker'>
                                        <div className='img_recommend'>
                                            <img src={club} className='couv_recommend' />
                                        </div>
                                        <div className='desc_recommend'>
                                            <span className='title_recommend'>{marker.name}</span>
                                            <span className='lieu_recommend'><FontAwesomeIcon icon={faMapMarkerAlt} />{marker.lieu}</span>
                                            <span className='date_recommend'>12 Aug - 16 Aug</span>
                                            <span className='price_recommend'>21 &euro;</span><span className='price_paid'>18 &euro;</span>
                                        </div>
                                    </div>
                                </Popup>
                            </Marker>
                        ))}

                        
                    </MapContainer>
                </div>
                <div className='icone_navigation'>
                    <a href="#" className='current'>
                        <svg id="icone_map" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.83301 7.86329V21.1966L8.66634 17.8633L15.333 21.1966L21.1663 17.8633V4.52995L15.333 7.86329L8.66634 4.52995L2.83301 7.86329Z" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8.66699 4.52995V17.8633" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.333 7.86328V21.1966" stroke="#0A0A0B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <a href="/student-event" >
                        <svg id="icone_list" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 6.86328H21" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 12.8633H21" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 18.8633H21" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 6.86328H3.01" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 12.8633H3.01" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M3 18.8633H3.01" stroke="#0A0A0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                </div>
                <div className='icone_navigation_right'>
                    <a className='btn_create_event' href="/bde-create-event"><FontAwesomeIcon icon={faPlusCircle} /></a>
                </div>
                <MenuBottomStudent currentMenu='activity' />
            </div>
        </Container>
    
        </>
    );
}
export default EventStudentMap;