import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

function EventTarifDisplay() {

  const [quantity, setQuantity] = useState(0);
  const [prixTotal,setPrixTotal] = useState(0);
  const [tarifselected, setTarifSelected] = useState([]);
  

  const [tarifs, setTarifs] = useState([
        {id:1, nom:"Adulte +26 ans", price:15, info:"Prix par personne", reduction:10, capaciteMaximale:10, quantity:0},
        {id:2, nom:"Adulte -26 ans", price:12, info:"Prix par personne", reduction:10,capaciteMaximale:6, quantity:0},
        {id:3, nom:"Groupe de +3 personnes", price:13, info:"Prix par personne", reduction:10, capaciteMaximale:12, quantity:0},
  ]);

  const updateQuantity = (id, newQuantity) => {

    if (newQuantity >= 0) {
        //remplir les tab tarifs selectionnes
        const updatedTarifs = tarifs.map((tarif) =>
            tarif.id === id ? { ...tarif, quantity: newQuantity} : tarif,
        );
        setTarifs(updatedTarifs);
        //ajouter le montant apres 
    }

    console.log(tarifs);
    
  };

  const calculateTotal = () => {
    return tarifs.reduce((total, tarif) => total + tarif.price * tarif.quantity, 0);
  };


  return (
    <>
    {tarifs ? tarifs.map((tarif, index) => ( 
        <div className="div_tarif">
            <div className="groupe_choix_tarif">
                <div className="groupe_prix_tarif">
                    
                    <div className='groupe_nom_info'>
                        <span className="tarif_nom">{tarif.nom}</span>
                        <p className='detail_tarif'><span className="prix_personne">{tarif.info}</span> x <span id="tarif_personne0" className="tarif_pers">&nbsp;{tarif.price}&euro;</span></p>
                    </div>
                    <div className='groupe_calcul_tarif' >
                        <div className='groupe_input'>
                            <Button className='btn_rond' onClick={() => updateQuantity(tarif.id, tarif.quantity - 1)}>-</Button>
                            <span className='span_pers'  > {tarif.quantity}</span>
                            <Button className='btn_rondplus' onClick={() => updateQuantity(tarif.id, tarif.quantity + 1)}>+</Button>
                        </div>
                        <div className='montat_tarif'><span  className='tarif_pers' >{tarif.quantity * tarif.price}</span> &euro;</div>

                    </div>
                </div>
                
            </div>
        </div>
    )) : '..'}
    <p className='separateur_point'></p>
    <div className="groupe_total">
        <h3 className="titre_card">Total</h3>
        <span id="prixTotal" className="total_prix">{calculateTotal()}&euro;</span>
    </div>
    </>
  );
}

export default EventTarifDisplay;
