/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { privileges } from "../../../data/options";
import fleche from "./images/arrow-left.svg";
import "./SignupPage.css";

import { Row, Col, Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateFormData } from "../../../redux/signup/actions";

function ChoosePrivilegePage() {

  const dispatch= useDispatch();
  let navigate = useNavigate();

  const [privilege, setPrivilege] = useState(
    privileges.find((privilege) => privilege.defaultChecked)?.path
  );

  const handleSelection = (e) => {
    setPrivilege(e.target.value);
  };

  const handleClick = () => {
    dispatch(updateFormData({privilege: privileges.find((item) => item.path === privilege)?.value}));
    navigate(`/inscription/${privilege}`);
  };

  return (
    <>
      <Container fluid className="full flex_divider">
        <div className="mobile_div">
          <div className="col-md-12 col-lg-12 dv_retour">
            <a className="black" href="/">
              <img src={fleche} alt="fleche gauche" />
            </a>
            <h2 className="titre_h2">S'inscrire</h2>
          </div>
          <div className="col-md-12 col-lg-12">
            <Row className="formSignup">
              <Col md={12} lg={12}>
                <p className="p_content">Décrivez votre rôle dans Budease</p>
                <div key="groupeRole" className="mb-12 groupe_check">
                  {privileges.map((option) => {
                    return (
                      <Form.Check
                        key={option.value}
                        label={option.label}
                        name="privilege"
                        type="radio"
                        id={option.value}
                        value={option.path}
                        disabled={option.disabled}
                        required
                        onChange={(e) => handleSelection(e)}
                        defaultChecked={option.defaultChecked}
                      />
                    );
                  })}
                </div>
              </Col>
              <Col md={12} lg={12} className="center">
                <div className="bottom_submit">
                  <Button
                    className="btn_signup"
                    type="button"
                    onClick={handleClick}
                  >
                    Suivant
                  </Button>
                </div>
              </Col>
              <Col md={12} lg={12} className="center">
                <div className="bottom_txt">
                  En vous inscrivant, vous acceptez{" "}
                  <a href="#" className="link">
                    nos conditions générales et nos politiques de
                    confidentialité
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ChoosePrivilegePage;
