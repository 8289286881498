import ApiProvider from "../../helpers/ApiProvider";
import tokenManager from "../../helpers/TokenManager";
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  UPDATE_USER,
} from "./types";

export const authRequest = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: AUTH_REQUEST });

    const response = await ApiProvider.api.post(
      "/user/authentificate",
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.data.success) {
      const { accessToken, data } = response.data.data;

      // Set the token in the cookies
      tokenManager.setToken(accessToken);

      dispatch({ type: AUTH_SUCCESS, payload: data });
    } else {
      dispatch({ type: AUTH_FAILURE, payload: response.data.error.error });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: AUTH_FAILURE,
      payload: error.response?.data.error
        ? error.response?.data.error
        : error.message,
    });
  }
};

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER });

    const response = await ApiProvider.api.get("/user/fetch-user", {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.data.success) {
      dispatch({ type: FETCH_USER_SUCCESS, payload: response.data.data });
    } else {
      dispatch({
        type: FETCH_USER_FAILURE,
        payload: response.data.error.error,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: FETCH_USER_FAILURE,
      payload: error.response?.data.error
        ? error.response?.data.error
        : error.message,
    });
  }
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({type: UPDATE_USER, payload: data});
}
