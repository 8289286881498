/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { faCheckCircle, faExclamationCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { confirmAccount } from "../../../redux/signup/actions";
import errors from "../../../helpers/Errors";
import privileges from '../../../data/privileges';

function ConfirmPage(props) {
  const navigate = useNavigate();
  const signupState = useSelector((state) => state.signup);
  const { success , error , loading } = signupState;
  const authState = useSelector((state) => state.auth);
  const { user } = authState;

  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (token) dispatch(confirmAccount(token));
  }, [token]);

  const explorer = () => {
    if (user) {
      const role = user.privileges[0].toLowerCase();
      navigate(privileges[role].mainPage);
    }
  }

  return (
    <>
      <Container>
        <div className="col-md-12 col-lg-12">
          {loading && (
            <div className="loading">
              <FontAwesomeIcon
                icon={faSpinner}
                className="blue_check"
                size="5x"
                pulse
              ></FontAwesomeIcon>
            </div>
          )}
          {success && user && (
            <div className="success_div">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="blue_check"
              ></FontAwesomeIcon>
              <p className="title_pop">Confirmation Réussie</p>
              <p>
                Félicitations ! Votre compte a été confirmé avec succès. Vous
                pouvez désormais accéder à toutes les fonctionnalités de notre
                plateforme.
              </p>
              <p></p>
              <p> Bienvenue !</p>
              <Button className="btn_ok" onClick={explorer}>Explorer Budease</Button>
            </div>
          )}

          {!success && error && (
            <div className="success_div">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="error"
              ></FontAwesomeIcon>
              <p className="title_pop">Erreur</p>
              <p>
               {errors[error?.error]}
              </p>
              <p></p>
              <p>Merci!</p>
              <Button className="btn_ok">Retour</Button>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default ConfirmPage;
