/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect} from 'react';
import { useSelector , useDispatch } from "react-redux";
import fleche from '../../assets/images/arrow-left.svg';
import { faCheckCircle, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Container, Row, Col, Form, FormControl, FormGroup, Button, FormLabel, Modal} from 'react-bootstrap';
import { fetchCategories } from '../../redux/categories/actions';
import defaultCoverImage from "../../assets/images/default-img.gif";

import { uid } from 'uid';
import { updateFormData } from '../../redux/event/actions';

function DetailEventForm({next}) {

    const dispatch = useDispatch();

    const { categories } = useSelector((state) => state.categorie);
    const { formData } = useSelector((state) => state.event);

    const [validated,setValidated] = useState(false);
    const [coverImage, setCoverImage] = useState(formData?.coverImage ? URL.createObjectURL(formData?.coverImage) : defaultCoverImage);

    const [detailEventData, setDetailEventData] = useState({
        name :formData?.name || "",
        categorie :formData?.categorie || 0,
        description : formData?.description  || "" ,
        participant : formData?.participant  || 1 ,
        coverImage : formData?.coverImage || null,
    });

    const [validatedCategorie,setValidatedCategorie] = useState(formData?.categorie !== 0);
    const [showErrorCategorie,setShowErrorCategorie] = useState(false);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetailEventData({
          ...detailEventData,
          [name]: value,
        });
    };

    const handleSliderChange = (event) => {
        setDetailEventData({
            ...detailEventData,
            [event.target.name]: event.target.value,
        });
    };

    const handeDetailEvent = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false || !validatedCategorie) {
            setValidated(true);
            setShowErrorCategorie(!validatedCategorie);
        }else{
            setShowErrorCategorie(false);
            next({
                ...formData,  
                ...detailEventData,  
            })
        }
    };

    const handleSelect = (e) => {
        const { name, value } = e.target;
        setValidatedCategorie(value !== "0");
        setDetailEventData({
            ...detailEventData,
            [name]: value
        })
    }

    const handleUpload = (e) => {
        const coverImage = e.target?.files[0];
        if(coverImage) setCoverImage(URL.createObjectURL(coverImage));
        setDetailEventData({
          ...detailEventData,
          coverImage,
        });
    };

    useEffect(() => {
        dispatch(fetchCategories())
    },[])

    return (
        <>
        <div className='col-md-12 col-lg-12 dv_retour'>
            <a className='black' href="/"><img src={fleche} alt="fleche gauche" /> Créer un événement</a>
            <ul className='inline_progress event'>
                <li className='current'>&nbsp;</li>
                <li>&nbsp;</li>
                <li>&nbsp;</li>
            </ul>
            <h2 className="titre_h2">Quel est votre événement ?</h2>
        </div>
        <div className='col-md-12 col-lg-12 conteneur'>
            <Form className='formDetailEvent' noValidate validated={validated} onSubmit={handeDetailEvent}>
                <Row>
                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3" >
                            <FormLabel>Nom de l'événement</FormLabel>
                            <div className='inline_input'>
                                <FormControl className='input-rond' type="text" id="eventName" placeholder="Event Name" name="name" value={detailEventData?.name}   onChange={handleChange} required={true}></FormControl>
                                <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3" >
                            <FormLabel>Catégorie de l'événement : </FormLabel>
                            <Form.Select  className="input-rond" aria-label="catégorie activite" id="eventCategory" name="categorie" value={detailEventData?.categorie} onChange={handleSelect}>
                                <option key={uid()} value={0}>[ séléctionner une catégorie]</option>
                                { categories &&
                                    categories?.map((categorie) => (
                                        <option key={uid()} value={categorie.id}>{categorie.label}</option>
                                    ))
                                }
                            </Form.Select>
                            {
                                showErrorCategorie &&    <span className='text-danger'>Veuillez sélectionner une catégorie !</span>
                            }
                         
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <Form.Group className="mb-3" >
                            <Form.Label>Description</Form.Label>
                            <Form.Control className="input-rond" as="textarea" id="eventDescription" name="description" placeholder="Description" rows={8} value={detailEventData?.description}  onChange={handleChange} required={true}/>
                            <Form.Control.Feedback type="invalid">Ce champ est requis !</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12} className='top30'>
                        <Form.Group className="mb-3" >
                            <Form.Label>Nombre de participant</Form.Label>
                            <Form.Range
                                name="participant"
                                id="eventParticipant"
                                value={detailEventData?.participant} 
                                onChange={handleSliderChange}
                                min={1}
                                max={400}
                                step={1}
                            />
                            <span>{detailEventData?.participant}</span>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={12}>
                        <FormGroup className="mb-3" >
                            <FormLabel>Upload image </FormLabel>
                            <input accept=".jpg, .jpeg, .png" type="file" name="coverImage" id="coverImage" style={{ display: 'none' }} onChange={handleUpload} />
                        </FormGroup>
                    </Col>
                    <Col md={12} lg={12}>
                        <div className='img_couverture' style={{backgroundImage: `url(${coverImage})`}} onClick={() => document.getElementById('coverImage').click()}>
                        </div>
                    </Col>
                </Row>
                <Row>  
                    <Col md={12} lg={12} className="center">
                        <div className='top30'><Button className="btn_login" type="submit">Continue</Button></div>
                    </Col>
                </Row>
            </Form>
        </div>
        </>
    );
}
export default DetailEventForm;